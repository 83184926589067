.social-box {
	min-height: 160px;
	margin-bottom: 20px;
	text-align: center;
	background: $white;
	border-radius: 2px;
	box-shadow: 0 0 0 1px rgba(181, 201, 227, 0.12), 0 8px 16px 0 rgba(181, 201, 227, 0.24);
	i {
		display: block;
		font-size: 40px;
		background: #e9ecef;
		line-height: 110px;
	}
	&.facebook i {
		color: $white;
		background: #5e5baa;
		border-radius: 2px 2px 0 0;
	}
	ul {
		list-style: none;
		display: inline-block;
		margin: 7px 0 0;
		padding: 10px;
		width: 100%;
		li {
			color: #949ca0;
			font-size: 14px;
			font-weight: 700;
			padding: 0 10px 0 0;
			text-align: center;
			display: block;
			float: left;
			width: 50%;
			line-height: 2;
			strong {
				display: block;
				font-size: 17px;
				line-height: 1;
				color: $color;
			}
			span {
				font-size: 15px;
				font-weight: 500;
				color: $color;
				text-transform: uppercase;
			}
			&:last-child {
				padding-left: 10px;
				padding-right: 0;
				text-align: center;
			}
		}
	}
	&.twitter i {
		color: $white;
		background: #e67605;
		border-radius: 2px 2px 0 0;
	}
	&.linkedin i {
		color: $white;
		border-radius: 2px 2px 0 0;
	}
	&.google-plus i {
		color: $white;
		background: #ff3b2e;
		border-radius: 2px 2px 0 0;
	}
}

.statistics-box.with-icon {
	position: relative;
	padding-left: 80px;
	text-align: right;
	.ico {
		&.small {
			font-size: 50px;
		}
		position: absolute;
		top: 8px;
		left: 0;
		width: 80px;
		height: 80px;
		font-size: 70px;
		line-height: 80px;
		text-align: center;
	}
}

.content-box h4 {
	font-size: 43px !important;
}

.counter {
	font-size: 35px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.counter-1 {
	font-size: 40px;
	font-weight: 600;
}

.todo-item {
	+.todo-item {
		margin-top: 8px;
	}
	.checkbox {
		margin-bottom: 6px;
	}
	input:checked+span {
		text-decoration: line-through;
	}
}

.wx h1 {
	font-size: 65px !important;
	margin-bottom: 15px;
	margin-top: 0;
}

.social-title {
	text-transform: uppercase;
	letter-spacing: 1px;
	text-shadow: none;
	color: $white !important;
}

.socials {
	display: flex;
	li {
		margin: 0 12px;
		margin-right: 15px;
	}
}

.text-shadow1 {
	text-shadow: 0 10px 10px rgba(39, 29, 96, 0.4);
}

.count {
	font-size: 37px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.description-block {
	display: block;
	margin: 10px 0;
	text-align: center;
	> {
		.description-header {
			margin: 0;
			padding: 0;
			font-weight: 600;
			font-size: 16px;
		}
		.description-text {
			text-transform: uppercase;
			color: $color;
		}
	}
}

.socailicons {
	.fa {
		font-size: 70px;
		text-align: right;
		position: absolute;
		top: 13px;
		right: -5px;
		outline: 0;
	}
	color: $white;
	text-shadow: 1px 1px 2px $black-5;
	a {
		text-decoration: none;
	}
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: .7;
	z-index: 1;
}

.masthead {
	position: relative;
	overflow: hidden;
	padding-bottom: 3rem;
	z-index: 2;
	.masthead-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		min-height: 35rem;
		height: 100%;
	}
	.masthead-content {
		h1 {
			font-size: 2rem;
		}
		p {
			font-size: 1rem;
			strong {
				font-weight: 700;
			}
		}
		.input-group-newsletter {
			input {
				height: auto;
				font-size: 1rem;
				padding: 1rem;
			}
			button {
				font-size: .8rem;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 1px;
				padding: 1rem;
			}
		}
	}
}

@media (min-width: 1025px) {
	.masthead .masthead-content {
		padding-left: 7rem;
		padding-right: 7rem;
	}
}

@media (min-width: 992px) {
	.masthead {
		height: 100%;
		min-height: 0;
		width: 40%;
		padding-bottom: 0;
		float: right;
		.masthead-bg {
			min-height: 0;
		}
		.masthead-content {
			h1 {
				font-size: 2.5rem;
			}
			p {
				font-size: 1rem;
			}
		}
	}
	.sticky-wrapper.is-sticky {
		.horizontal-main .ad-post {
			margin-top: -0.25rem !important;
		}
		.horizontalMenu>.horizontalMenu-list>li>a.active {
			background-color: #e67605;
			color: $white;
			.fa {
				color: $white !important;
			}
		}
		.desktoplogo-1 {
			display: block !important;
			padding: 1.25rem 0;
			margin: 0;
			float: left;
		}
	}
	.desktoplogo-1,
	.sticky-wrapper.is-sticky .desktoplogo {
		display: none;
	}
	.map-list-card {
		margin-top: 54px;
		border-top: 1px solid #e4e6f1;
		z-index: 99;
		border-left: 0;
		border-right: 0;
	}
}

.ico-listing.table {
	td,
	th {
		padding: .75rem;
		vertical-align: middle;
	}
}

.sticky-pin {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
	box-shadow: 2px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
}

.sticky.sticky-pin .horizontal-main {
	background: #1e345f !important;
	box-shadow: 0 4px 10px 0 $black-3;
}

.notifyimg {
	color: $white;
	float: left;
	height: 40px;
	line-height: 36px;
	margin-right: 10px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
	border-radius: 50%;
}

.app-selector ul {
	margin-bottom: 0;
}

.block {
	display: block !important;
}

.user-semi-title {
	font-size: 12px;
}

.floating {
	animation: floating 3s ease infinite;
	will-change: transform;
}

#global-loader {
	position: fixed;
	z-index: 50000;
	background: $white;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
}

.loader-img {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 45%;
	margin: 0 auto;
}

@media (min-width: 992px) {
	.Realestate-content .select2-container--default .select2-selection--single {
		border-radius: 0 !important;
	}
}

#popup {
	position: absolute;
	width: auto;
	height: 30px;
	background: #fe6b1f;
	display: none;
	color: $white;
	border-radius: 2px;
}

#copy {
	background: 0 0;
	color: $white;
	font-weight: 700;
	padding: 8px 25px;
	border: 0;
}

.horizontal-line {
	margin-top: 1rem;
	margin-bottom: 1rem;
	width: 80%;
}

.close {
	float: right;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.5;
	color: #a0afc7;
	text-shadow: 0 1px 0 $white;
	opacity: .7;
	transition: .3s color;
	&:focus,
	&:hover {
		color: #a0afc7;
		text-decoration: none;
		opacity: .75;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
}

@media (max-width: 576px) {
	.header-text h1 {
		font-size: 2rem;
	}
	.slide-header-text h1 {
		font-size: 2rem !important;
	}
	.classified-video h1 {
		font-size: 1.2rem;
	}
	.section-title h2 {
		font-size: 1.5rem;
	}
	.content-text .display-5 {
		font-size: 1.8rem;
	}
	.banner-section .header-text h1,
	.header-text1 .text-property h1 {
		font-size: 2rem !important;
	}
}

@media print {
	*,
	 ::after,
	 ::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	a:not(.btn) {
		text-decoration: underline;
	}
	abbr[title]::after {
		content: " (" attr(title) ")";
	}
	pre {
		white-space: pre-wrap !important;
	}
	blockquote,
	pre {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	img,
	tr {
		page-break-inside: avoid;
	}
	h2,
	h3,
	p {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
	@page {
		size: a3;
	}
	body,
	.container {
		min-width: 992px !important;
	}
	.navbar {
		display: none;
	}
	.badge {
		border: 1px solid $black;
	}
	.table,
	.text-wrap table {
		border-collapse: collapse !important;
	}
	.table {
		td,
		th {
			background-color: $white !important;
		}
	}
	.text-wrap table {
		td,
		th {
			background-color: $white !important;
		}
	}
	.table-bordered {
		td,
		th {
			border: 1px solid #e4e6f1 !important;
		}
	}
	.text-wrap table {
		td,
		th {
			border: 1px solid #e4e6f1 !important;
		}
	}
}

blockquote {
	color: $color;
	padding-left: 2rem;
	border-left: 3px solid rgba(227, 237, 252, 0.5);
	margin: 0 0 1rem;
}

.blockquote-reverse {
	padding-right: 15px;
	padding-left: 0;
	text-align: right;
	border-right: 3px solid rgba(227, 237, 252, 0.5);
	border-left: 0;
}

blockquote {
	&.float-right {
		padding-right: 15px;
		padding-left: 0;
		text-align: right;
		border-right: 3px solid rgba(227, 237, 252, 0.5);
		border-left: 0;
	}
	p {
		margin-bottom: 1rem;
	}
	cite {
		display: block;
		text-align: right;
		&:before {
			content: '— ';
		}
	}
}

hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
	border: 0;
	border-top: 1px solid #ced8e6;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

.section-nav {
	background-color: #f8f9fa;
	margin: 1rem 0;
	padding: .5rem 1rem;
	border: 1px solid #e4e6f1;
	border-radius: 2px;
	list-style: none;
	&:before {
		content: 'Table of contents:';
		display: block;
		font-weight: 600;
	}
}

@media print {
	.container {
		max-width: none;
	}
}

.page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100%;
}

body.fixed-header .page {
	padding-top: 4.5rem;
}

@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-right: 22rem;
	}
}

.page-main {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.page-content {
	margin: .75rem 0;
}

@media (min-width: 768px) {
	.page-content {
		margin: 1.5rem 0 !important;
	}
}

.page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
	position: relative;
	min-height: 50px;
	background: $white;
}

.users-list .page-header {
	background: 0 0;
	padding: 0;
	border: 0;
}

.bannerimg .breadcrumb {
	display: inline-flex;
}

.page-title {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 2.5rem;
}

.page-title-icon {
	color: #6e82a9;
	font-size: 1.25rem;
}

.page-subtitle {
	font-size: .8125rem;
	color: #5f6877;
	position: relative;
	top: 19px;
	right: 59rem;
	a {
		color: inherit;
	}
}

.page-options {
	margin-left: auto;
}

.page-description {
	margin: .25rem 0 0;
	color: #5f6877;
	a {
		color: inherit;
	}
}

.page-single {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.content-heading {
	font-weight: 400;
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
	line-height: 1.25;
	&:first-child {
		margin-top: 0;
	}
}

.aside {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 22rem;
	background: $white;
	border-left: 1px solid #e4e6f1;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 100;
	visibility: hidden;
	box-shadow: 0 5px 20px $black-1;
}

@media (min-width: 1600px) {
	body.aside-opened .aside {
		visibility: visible;
	}
}

.aside-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow: auto;
}

.icon i {
	vertical-align: -1px;
}

a.icon {
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #1a1a1a !important;
	}
}

.o-auto {
	overflow: auto !important;
}

.o-hidden {
	overflow: hidden !important;
}

.shadow {
	box-shadow: 0 1px 2px 0 $black-05 !important;
}

.shadow-none {
	box-shadow: none !important;
}

.user-img {
	margin-top: 2px;
}

@media (max-width: 992px) {
	.admin-navbar {
		.nav-link {
			span :not(.square-8) {
				display: block;
			}
			width: 100%;
			text-align: left;
			display: block;
		}
		.nav-item {
			min-width: 100%;
		}
		&.collapse.show {
			display: block;
		}
		display: none;
		.mega-dropdown .sub-item .section-label {
			margin-top: 10px;
		}
		.sub-item .sub-with-sub ul li a {
			padding-left: 20px;
		}
		.nav {
			.nav-item.with-sub .nav-link.active {
				position: relative !important;
			}
			.nav-link .sub-item {
				position: inherit !important;
			}
		}
		.container {
			background: $white;
			z-index: 100;
		}
		.sub-item {
			position: relative !important;
			top: 0 !important;
		}
	}
	.nav-item.with-sub,
	.with-sub .nav-link {
		display: block !important;
	}
	.about-con {
		border-bottom: 1px solid #e4e6f1;
	}
	.slider-images .form-group {
		border-radius: 2px;
	}
	.header-style2 .top-bar,
	.header-style3 .top-bar {
		.top-bar-left .socials li {
			margin: 0 !important;
			padding: 10px !important;
		}
		padding: 0px !important;
	}
}

@media (max-width: 1279px) and (min-width: 992px) {
	.admin-navbar .nav-link {
		padding: 1rem .57rem;
	}
	.pabout.p-8 {
		padding: 17px !important;
	}
}

@media (max-width: 480px) {
	.list-media .info {
		.text-right {
			display: none;
		}
		padding-bottom: 15px;
	}
	.tabs-menu ul li {
		width: 100%;
		margin: 5px 15px;
		a {
			width: 100%;
		}
	}
	.cal1 .clndr .clndr-table tr {
		height: 50px !important;
	}
	.tabs-menu1 ul li {
		width: 100%;
		margin: 2px;
		a {
			width: 100%;
		}
	}
	.wizard-card .nav-pills>li {
		text-align: center;
		padding: 9px !important;
	}
	.form-control.custom-select.w-auto {
		display: none;
	}
	.mail-inbox .badge {
		margin: 0;
	}
	.construction .display-5 {
		font-size: 1.5rem;
	}
	.jumbotron .display-3 {
		font-size: 2.5rem;
	}
	.mail-option .hidden-phone {
		display: none;
	}
}

@media (max-width: 767px) {
	.avatar {
		max-width: inherit;
	}
	.card-tabs {
		display: block !important;
	}
	.header-brand {
		line-height: 2.7rem;
	}
	.header .input-icon.mt-2 {
		margin-top: 5px !important;
	}
	.footer .privacy {
		text-align: center !important;
	}
	.shop-dec .col-md-6 {
		&.pr-0 {
			padding-right: 0.75rem !important;
		}
		&.pl-0 {
			padding-left: 0.75rem !important;
		}
	}
}

@media (max-width: 375px) {
	.nav-link span,
	.searching1 i {
		margin-top: .5rem;
	}
	.construction h3 {
		font-size: 2.8rem !important;
	}
}

@media (max-width: 411px) {
	.nav-link span,
	.searching1 i {
		margin-top: .5rem;
	}
}

@media (max-width: 414px) {
	.nav-link span {
		margin-top: .6rem;
	}
	.searching1 i {
		margin-top: .5rem;
		top: 14px !important;
	}
}

@media (max-width: 768px) {
	.nav-tabs .nav-link {
		width: 100%;
	}
	.page-subtitle {
		display: none;
	}
	.richText .richText-toolbar ul li a {
		border: rgba(0, 40, 100, 0.12) solid 1px;
	}
	.ticket-card {
		.col-md-1 {
			width: 100%;
			display: block;
		}
		img {
			display: block;
			text-align: Center;
			margin: 0 auto;
		}
	}
	.dataTables_wrapper {
		.dataTables_info,
		.dataTables_paginate {
			margin-top: 10px;
		}
	}
	.page-title {
		line-height: 1.5rem;
	}
	.carousel-caption {
		display: none;
	}
	.demo-gallery>ul>li {
		width: 100% !important;
	}
	ul.inbox-pagination li span {
		display: none;
	}
	.btn-appointment .btn {
		font-size: 11px;
	}
	.wideget-user-tab .tabs-menu1 ul li .active {
		border: 1px solid #e4e6f1 !important;
		border-radius: 2px;
	}
}

@media (max-width: 568px) and (min-width: 480px) {
	.container-messages {
		position: absolute;
		top: 50px !important;
	}
	.messages-list {
		top: 24px !important;
		bottom: 73px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 71px !important;
	}
	.messages-left-footer {
		bottom: 40px !important;
		display: none;
	}
	.messages-left .card-header {
		display: none;
	}
	.message-footer,
	.message-header {
		height: 50px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
}

@media (max-width: 990px) and (min-width: 569px) {
	.message-header,
	.message-footer {
		height: 50px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 80px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
	.messages-left .card-header {
		display: none;
	}
	.messages-list {
		top: 24px !important;
	}
}

@media (max-width: 990px) {
	.header-toggler {
		margin: 6px;
	}
	.nav-tabs .nav-link {
		width: 100%;
	}
	form.convFormDynamic button.submit {
		margin: 4px !important;
	}
	.messages-list .media {
		padding: 9px !important;
	}
	.nav-tabs {
		z-index: 1000;
	}
}

.page-breadcrumb {
	background: 0 0;
	padding: 0;
	margin: 1rem 0 0;
	font-size: .875rem;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	.breadcrumb-item {
		color: #6e82a9;
		&.active {
			color: #5f6877;
		}
	}
}

@media (min-width: 768px) {
	.page-breadcrumb {
		margin: -.5rem 0 0;
	}
}

@media (max-width: 320px) {
	.fc-toolbar .fc-right {
		float: right;
		width: 100%;
		text-align: center;
		margin: 10px 0;
	}
	.item-card9-footer .btn.btn-light {
		font-size: 11px;
	}
	.construction h3 {
		font-size: 2.3rem !important;
	}
	.header-search .support-header h6 {
		font-size: 8px !important;
	}
	.list-inline.wizard {
		display: flex;
	}
}

.page-total-text {
	margin-right: 1rem;
	-ms-flex-item-align: center;
	align-self: center;
	color: #5f6877;
}

.tilebox-one i {
	font-size: 62px;
}

.reg {
	text-align: center;
	font-size: 50px;
	color: #2e1170;
	float: right;
}

.profie-img .flex-md-row img {
	width: 60px;
}

ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: .5rem;
}

.legend li {
	padding: 5px;
	display: -webkit-inline-box;
}

.stretch-card {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: stretch;
	justify-content: stretch;
	>.card {
		width: 100%;
		min-width: 100%;
	}
}

.statistics-box {
	padding-top: 14px;
	min-height: 80px;
	text-align: center;
	position: relative;
	padding-left: 80px;
	text-align: right;
	.ico {
		&.small {
			font-weight: 900;
			font-size: 42px;
		}
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 80px;
		font-size: 70px;
		line-height: 80px;
		text-align: center;
	}
	h2 {
		font-weight: 600 !important;
	}
}

.visitor-list {
	p {
		font-size: 12px;
	}
	i {
		font-size: 20px;
	}
}

.sales-relative {
	position: relative;
}

.product-price {
	font-size: 1rem;
	strong {
		font-size: 1.5rem;
	}
}

@-webkit-keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	100%,
	60% {
		left: 100%;
		right: -90%;
	}
}

@keyframes indeterminate {
	0% {
		left: -35%;
		right: 100%;
	}
	100%,
	60% {
		left: 100%;
		right: -90%;
	}
}

@-webkit-keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	100%,
	60% {
		left: 107%;
		right: -8%;
	}
}

@keyframes indeterminate-short {
	0% {
		left: -200%;
		right: 100%;
	}
	100%,
	60% {
		left: 107%;
		right: -8%;
	}
}

@-webkit-keyframes loader {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loader {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.link-overlay {
	position: relative;
	&:hover .link-overlay-bg {
		opacity: 1;
	}
}

.link-overlay-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $black-5;
	display: -ms-flexbox;
	display: flex;
	color: $white;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.25rem;
	opacity: 0;
	transition: .3s opacity;
}

textarea[cols] {
	height: auto;
}

.searching1 i {
	position: absolute;
	top: 21px;
	margin-left: 15px;
	font-size: 16px;
	color: #fcfdff;
}

.search-inline {
	width: 100%;
	left: 0;
	padding-top: 1.5rem;
	padding-bottom: .75rem;
	top: 0;
	position: absolute;
	opacity: 0;
	visibility: hidden;
	background-color: $white;
	box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	z-index: 9;
	transition: all .1s ease-in-out;
	&.search-visible {
		opacity: 1;
		visibility: visible;
		padding-top: 3rem;
		right: 0;
		padding-bottom: .5rem;
		border: 1px solid #e4e6f1;
		animation: fadeInDown .1s ease-in-out;
	}
	button[type=submit] {
		position: absolute;
		right: 52px;
		top: 0;
		background-color: transparent;
		border: 0;
		top: 0;
		right: 80px;
		padding: 0;
		cursor: pointer;
		width: 80px;
		height: 99%;
		background: $white;
		color: #6e82a9;
	}
	.form-control {
		border: 0;
		font-size: 15px;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 99%;
		font-weight: 700;
		outline: 0;
	}
	.search-close {
		position: absolute;
		top: 21px;
		right: 0;
		color: #6e82a9;
		width: 80px;
		height: 100%;
		text-align: center;
		display: table;
		background: $white;
		text-decoration: none;
		i {
			display: table-cell;
			vertical-align: middle;
		}
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.sparkline {
	display: inline-block;
	height: 2rem;
}

.jqstooltip {
	box-sizing: content-box;
	font-family: inherit !important;
	background: #333 !important;
	border: none !important;
	border-radius: 2px;
	font-size: 11px !important;
	font-weight: 700 !important;
	line-height: 1 !important;
	padding: 6px !important;
	.jqsfield {
		font: inherit !important;
	}
}

.social-links li a {
	background: #f8f8f8;
	border-radius: 50%;
	color: #6e82a9;
	display: inline-block;
	height: 1.75rem;
	width: 1.75rem;
	line-height: 1.75rem;
	text-align: center;
}

@-webkit-keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

@keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

.status-icon {
	content: '';
	width: .5rem;
	height: .5rem;
	display: inline-block;
	background: currentColor;
	border-radius: 50%;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
	margin-right: .375rem;
	vertical-align: middle;
}

.status-animated {
	-webkit-animation: 1s status-pulse infinite ease;
	animation: 1s status-pulse infinite ease;
}

.chips {
	margin: 0 0 -.5rem;
}

.team i {
	margin-left: 10px;
	float: right;
	margin-top: 10px;
	color: #313148;
}

.chips .chip {
	margin: 0 .5rem .5rem 0;
}

.chip {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
	font-size: .875rem;
	font-weight: 500;
	color: $color;
	margin: 2px;
	padding: 0 .75rem;
	border-radius: 1rem;
	background-color: #f6f7fb;
	transition: .3s background;
	.avatar {
		float: left;
		margin: 0 .5rem 0 -.75rem;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
	}
}

a.chip:hover {
	color: $white;
	text-decoration: none;
}

.emp-tab table {
	margin-bottom: 0;
	border: 0;
}

.stamp {
	color: $white;
	background: #868e96;
	display: inline-block;
	min-width: 2rem;
	height: 2rem;
	padding: 0 .25rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 2px;
	font-weight: 600;
}

.stamp-md {
	min-width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
}

.stamp-lg {
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}

.example {
	padding: 1.5rem;
	border: 1px solid #e4e6f1;
	border-radius: 2px 2px 0 0;
	font-size: .9375rem;
}

.example-bg {
	background: #f3f3fa;
}

.example-column {
	margin: 0 auto;
	>.card:last-of-type {
		margin-bottom: 0;
	}
}

.example-column-1 {
	max-width: 20rem;
}

.example-column-2 {
	max-width: 40rem;
}

.imagecheck {
	margin: 0;
	position: relative;
	cursor: pointer;
}

.imagecheck-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.imagecheck-figure {
	border: 1px solid #e4e6f1;
	border-radius: 2px;
	margin: 0;
	position: relative;
	display: block;
	height: 100%;
}

.imagecheck-input:checked~.imagecheck-figure {
	border-color: rgba(0, 40, 100, 0.24);
}

.imagecheck-figure:before {
	content: '';
	position: absolute;
	top: .25rem;
	left: .25rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 1;
	border-radius: 2px;
	opacity: 0;
	transition: .3s opacity;
}

.imagecheck-input:checked~.imagecheck-figure:before {
	opacity: 1;
}

.imagecheck-image {
	max-width: 100%;
	opacity: .64;
	transition: .3s opacity;
	&:first-child {
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
	}
	&:last-child {
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;
	}
}

.imagecheck-input {
	&:checked~.imagecheck-figure .imagecheck-image,
	&:focus~.imagecheck-figure .imagecheck-image {
		opacity: 1;
	}
}

.imagecheck:hover .imagecheck-image {
	opacity: 1;
}

.imagecheck-caption {
	text-align: center;
	padding: .25rem .25rem;
	color: #6e82a9;
	font-size: .875rem;
	transition: .3s color;
}

.imagecheck-input {
	&:checked~.imagecheck-figure .imagecheck-caption,
	&:focus~.imagecheck-figure .imagecheck-caption {
		color: #3d4e67;
	}
}

.imagecheck:hover .imagecheck-caption {
	color: #3d4e67;
}

.colorinput {
	margin: 0;
	position: relative;
	cursor: pointer;
}

.colorinput-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.colorinput-color {
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 2px;
	border: 1px solid #e4e6f1;
	color: $white;
	box-shadow: 0 1px 2px 0 $black-05;
	&:before {
		content: '';
		opacity: 0;
		position: absolute;
		top: .25rem;
		left: .25rem;
		height: 1.25rem;
		width: 1.25rem;
		transition: .3s opacity;
		background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
	}
}

.colorinput-input:checked~.colorinput-color:before {
	opacity: 1;
}

svg {
	-ms-touch-action: none;
	touch-action: none;
}

#back-to-top {
	color: $white;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
	display: none;
	text-align: center;
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	-o-border-radius: 2px;
	z-index: 10000;
	height: 50px;
	width: 50px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color .1s linear;
	-moz-transition: background-color .1s linear;
	-webkit-transition: background-color .1s linear;
	-o-transition: background-color .1s linear;
	i {
		padding-top: 15px;
		font-size: 16px;
	}
}

.features {
	overflow: hidden;
	h2 {
		font-weight: 600;
		margin-bottom: 12px;
		text-align: center;
		font-size: 2.2em;
	}
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
	span {
		color: #43414e;
		display: block;
		font-weight: 400;
		text-align: center;
	}
}

.feature {
	.feature-svg {
		width: 25%;
	}
	.feature-svg3 {
		width: 12%;
	}
}

.col-sm-2 .feature {
	padding: 0;
	border: 0;
	box-shadow: none;
}

.feature {
	.fea-icon {
		position: relative;
		display: inline-block;
		width: 3rem;
		height: 3rem;
		vertical-align: middle;
		padding-top: 8px;
		border-radius: 50%;
		color: $white;
		i {
			font-size: 1.5rem;
		}
	}
	p {
		margin-bottom: 0;
	}
}

.box-shadow-0 {
	box-shadow: none !important;
}

#messages-main {
	position: relative;
	margin: 0 auto;
	&:after,
	&:before {
		content: " ";
		display: table;
	}
	.ms-menu {
		position: absolute;
		left: 0;
		top: 0;
		border-right: 1px solid #e4e6f1;
		padding-bottom: 50px;
		height: 100%;
		width: 240px;
		background: $white;
		&.toggled {
			display: block;
		}
	}
	.ms-body {
		overflow: hidden;
	}
	.ms-user>div {
		overflow: hidden;
		padding: 3px 5px 0 15px;
		font-size: 11px;
	}
	#ms-compose {
		position: fixed;
		bottom: 120px;
		z-index: 1;
		right: 30px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	}
}

#ms-menu-trigger {
	user-select: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding-right: 10px;
	padding-top: 19px;
	cursor: pointer;
	i {
		font-size: 21px;
	}
	&.toggled i:before {
		content: '\f2ea';
	}
}

.fc-toolbar:before,
.login-content:after {
	content: "";
}

.message-feed {
	padding: 10px;
	&.right {
		>.pull-right {
			margin-left: 15px;
		}
		.mf-content {
			background: #f3f3fa;
		}
	}
}

.mf-content {
	padding: 9px;
	border-radius: 2px;
	display: inline-block;
	max-width: 80%;
	position: relative;
}

.message-feed {
	&:not(.right) .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		left: -20px;
		top: 11px;
	}
	&.right .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-left-color: #f3f3fa;
		right: -20px;
		top: 11px;
	}
}

.mf-date {
	display: block;
	margin-top: 7px;
	>i {
		font-size: 14px;
		line-height: 100%;
		position: relative;
		top: 1px;
	}
}

.msb-reply {
	position: relative;
	margin-top: 3px;
	background: $white;
	padding: 10px;
	border-radius: 0 2px 2px 2px;
}

.four-zero,
.lc-block {
	box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
}

.msb-reply {
	textarea {
		width: 100%;
		font-size: 13px;
		border: 0;
		padding: 10px 15px;
		resize: none;
		background: 0 0;
	}
	button {
		top: 0;
		right: 0;
		border: 0;
		height: 100%;
		width: 60px;
		font-size: 20px;
		color: $white;
		border-radius: 0 2px 2px 0;
		line-height: 36px;
	}
}

.img-avatar {
	height: 37px;
	border-radius: 2px;
	width: 37px;
}

.p-15 {
	padding: 15px !important;
}

.action-header {
	position: relative;
	background: #f8f8f8;
	padding: 15px 13px 15px 17px;
}

.ah-actions {
	z-index: 3;
	float: right;
	margin-top: 7px;
	position: relative;
}

.actions {
	list-style: none;
	padding: 0;
	margin: 0;
	>li {
		display: inline-block;
	}
	&:not(.a-alt)>li>a>i {
		color: #939393;
	}
	>li>a {
		>i {
			font-size: 20px;
		}
		display: block;
		padding: 0 10px;
	}
}

.ms-body {
	background: $white;
	border-radius: 2px;
}

#ms-menu-trigger,
.message-feed.right {
	text-align: right;
}

#ms-menu-trigger,
.toggle-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
}

.message-feed {
	&.media .media-body,
	&.right .media-body {
		overflow: visible;
	}
}

.job-box-filter {
	label {
		width: 100%;
	}
	select.input-sm {
		display: inline-block;
		max-width: 120px;
		margin: 0 5px;
		border: 1px solid #e4e6f1;
		border-radius: 2px;
		height: 34px;
		font-size: 15px;
	}
	label input.form-control {
		max-width: 200px;
		display: inline-block;
		border: 1px solid #e4e6f1;
		border-radius: 2px;
		margin-left: 5px;
		font-size: 15px;
	}
	padding: 12px 15px;
	border-bottom: 1px solid #e4e6f1;
}

.job-box {
	display: inline-block;
	width: 100%;
	padding: 0;
}

.job-box-filter {
	a.filtsec {
		margin-top: 8px;
		display: inline-block;
		margin-right: 15px;
		padding: 4px 10px;
		font-family: Quicksand, sans-serif;
		transition: all ease .4s;
		background: #edf0f3;
		border-radius: 50px;
		font-size: 13px;
		color: #81a0b1;
		border: 1px solid #e2e8ef;
		&.active {
			color: $white;
			background: #16262c;
			border-color: #16262c;
		}
		i {
			color: #03a9f4;
			margin-right: 5px;
		}
		&:focus,
		&:hover {
			color: $white;
			background: #07b107;
			border-color: #07b107;
		}
		&:focus i,
		&:hover i {
			color: $white;
		}
	}
	h4 i {
		margin-right: 10px;
	}
}

@media (max-width: 600px) {
	.job-box-filter label {
		width: 100%;
		text-align: center;
	}
	.message-body h5 span {
		&.pending,
		&.unread {
			display: none;
		}
	}
}

#index-video {
	.index-video-container {
		position: relative;
		overflow: hidden;
		&:after {
			bottom: 0;
			content: "";
			left: 0;
			opacity: .7;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
		.videocaption {
			left: 1%;
			position: absolute;
			right: 1%;
			text-align: center;
			top: 30%;
			z-index: 2;
			h2 {
				color: $white;
				font-size: 4.5em;
				font-weight: 900;
				-webkit-box-sizing: content-box;
				-moz-box-sizing: content-box;
				box-sizing: content-box;
				border: none;
				color: rgba(255, 255, 255, 1);
				text-align: center;
				-o-text-overflow: clip;
				text-overflow: clip;
				text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.0980392), 0 0 5px rgba(0, 0, 0, 0.0980392), 0 1px 3px rgba(0, 0, 0, 0.298039), 0 3px 5px $black-2, 0 5px 10px rgba(0, 0, 0, 0.247059), 0 10px 10px $black-2, 0 20px 20px rgba(0, 0, 0, 0.14902);
				-webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-moz-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
			}
			h4 {
				color: $white;
				font-size: 2em;
				margin-top: 25px;
			}
			.btn-slide {
				background: #f62459;
				color: $white;
				font-size: 16px;
				font-weight: 300;
				margin: 25px auto 10px auto;
				padding: 20px 50px;
				border-radius: 0;
				text-transform: uppercase;
				&:hover {
					background: $white;
					color: #333;
				}
			}
		}
	}
	video {
		width: 100%;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
}

@media (min-width: 569px) and (max-width: 992px) {
	#index-video .index-video-container .videocaption {
		top: 33%;
	}
	.item-card-img h3.text-white {
		font-size: 1rem;
	}
	.item-card2 h4.bg-light.p-3 {
		font-size: .8rem;
	}
}

@media (max-width: 448px) {
	#index-video .index-video-container .videocaption {
		top: 12%;
	}
}

.video-list-thumbs {
	>li {
		margin-bottom: 12px;
		>a {
			display: block;
			position: relative;
			color: #6b6f80;
			padding: 8px;
			border-radius: 2px;
			transition: all .5s ease-in-out;
			border-radius: 2px;
		}
	}
	h2 {
		bottom: 0;
		font-size: 14px;
		height: 33px;
		margin: 8px 0 0;
	}
	span {
		font-size: 50px;
		opacity: .8;
		position: absolute;
		right: 0;
		left: 0;
		top: 42%;
		margin: 0 auto;
		text-align: center;
		transition: all .5s ease-in-out;
	}
	>li>a:hover .fa {
		color: $white;
		opacity: 1;
		text-shadow: 0 1px 3px $black-8;
	}
	.duration {
		background-color: rgb(0, 0, 0);
		border-radius: 0;
		color: $white;
		font-size: 13px;
		font-weight: 700;
		left: 12px;
		line-height: 9px;
		padding: 5px 8px;
		position: absolute;
		bottom: 12px;
		transition: all .5s ease;
	}
	>li>a:hover .duration {
		background-color: $black;
	}
}

@media (max-width: 768px) {
	.video-list-thumbs span {
		top: 29%;
	}
}

@media (min-width: 320px) and (max-width: 480px) {
	.video-list-thumbs {
		.fa {
			font-size: 35px;
		}
		h2 {
			bottom: 0;
			font-size: 12px;
			height: 22px;
			margin: 8px 0 0;
		}
	}
}

@media (max-width: 992px) {
	.navtab-wizard li a {
		padding: 1rem !important;
	}
	.search-background {
		background: 0 0;
		border-radius: 0;
		.form-group {
			margin-bottom: 10px !important;
			border-radius: 2px;
		}
	}
	.filter-product-info .table td {
		display: -webkit-box;
		padding: 3px !important;
	}
	.product-singleinfo .border-left {
		border-left: 0 !important;
	}
	.support .border-right {
		border-right: 0 !important;
	}
	.support-service.bg-dark {
		margin-bottom: 10px;
	}
	.wideget-user-info .wideget-user-warap .wideget-user-warap-r {
		margin-left: 5rem !important;
	}
	.wideget-user-desc .user-wrap {
		margin-top: 0 !important;
		margin-left: 10px !important;
	}
	.product-singleinfo .product-item2-align dt {
		width: 0 !important;
		float: none !important;
		display: -webkit-box !important;
	}
	dd {
		display: -webkit-box !important;
	}
	.product-singleinfo .product-item2-align dd {
		margin-left: 0 !important;
	}
	.item-single .item-single-desc .item-single-desc1 {
		display: block !important;
	}
	.product-5-desc a {
		float: right;
	}
	.get-contact a:last-child {
		margin-right: 0;
	}
	.header-search .header-nav .nav-cart .icon-cart span {
		display: none;
	}
	.header-nav .header-search-logo .header-logo .header-brand-img {
		height: auto;
		line-height: 2rem;
		vertical-align: bottom;
		margin-right: .5rem;
		width: auto;
	}
	.header-search .header-search-logo {
		text-align: center;
	}
	.wideget-user .wideget-user-desc .user-wrap a.btn {
		margin-bottom: 5px;
	}
	.product-single {
		.product-thumbimg,
		.product-info a {
			margin-bottom: 10px;
		}
	}
	.item-single .item-single-desc .input-group {
		margin-bottom: 10px;
	}
	.product-item2-desc .label-rating {
		margin-right: 4px !important;
	}
	.card-blogs .card-item-desc.p-0 {
		margin-left: 15px;
	}
	.license-card-price.text-center {
		text-align: left !important;
	}
	.product-details td a.btn {
		margin-bottom: 5px;
	}
	.wideget-user-info .wideget-user-warap {
		margin-top: 15px;
	}
	.product-singleinfo .product-item2-desc {
		margin-bottom: 10px;
	}
	.header-search .header-inputs {
		.input-group-prepend,
		.input-group>.form-control {
			width: 100%;
			margin-bottom: 10px;
		}
		.input-group-append {
			width: 100%;
			margin-bottom: 15px;
			>.btn {
				width: 100%;
				display: block;
			}
		}
	}
	.header-main {
		.get-contact {
			a {
				border-left: 0 !important;
				padding-left: 0 !important;
			}
			margin-bottom: 15px;
		}
		.social-icons {
			text-align: center;
			float: none !important;
			li:last-child {
				margin-right: 0;
			}
		}
	}
	.header-search .header-icons {
		.header-icons-link li {
			margin: 0 auto !important;
		}
		float: none !important;
		text-align: center !important;
	}
	.top-bar {
		text-align: center;
		.top-bar-left .contact {
			border-left: 0 !important;
			margin-left: 0 !important;
			padding-left: 0 !important;
			li {
				margin: 0 !important;
			}
		}
	}
	.item-search-menu ul li {
		text-align: center;
		border-radius: 2px;
		a {
			border-radius: 2px !important;
		}
	}
	.item-search-tabs {
		.form-group {
			&:last-child {
				margin-bottom: 0 !important;
			}
			margin-bottom: 10px !important;
		}
		.bg-white {
			background: 0 0 !important;
		}
		.tab-content {
			background: 0 0 !important;
			border: 0 !important;
		}
	}
	.item1-card {
		.item1-card-btn,
		.item1-card-info {
			margin-top: 15px;
		}
	}
	.items-blog-tab-heading .items-blog-tab-menu li {
		text-align: center;
		margin-bottom: 10px;
		a {
			display: block;
		}
	}
	.item2-gl .item2-gl-nav select,
	.item2-gl-nav h6 {
		text-align: center;
	}
	.item-card .item-card-img img,
	.item-card2-img img,
	.item-card9-img img,
	.product-info .product-info-img img,
	.item-card4-img img,
	.item-card5-img img,
	.item-card7-img img,
	.item-card8-img img,
	.overview .overview-img img,
	.video-list-thumbs img {
		width: 100%;
	}
	.search1 {
		background: 0 0 !important;
		.form-group {
			margin-bottom: 15px !important;
		}
	}
	.search2 a.btn {
		margin-top: 15px;
	}
	.search3 {
		background: 0 0 !important;
		.form-group {
			margin-bottom: 15px !important;
		}
	}
	.item-user-icons {
		margin-left: 10px;
	}
	.item1-card-tab .item1-tabs-menu ul li {
		width: 100%;
		text-align: center;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.user-wideget h6 {
		margin-top: 5px;
	}
	.header-main .top-bar {
		.contact,
		&.p-3 .contact {
			display: none;
		}
	}
	.bannerimg .header-text h1 {
		display: block;
		margin-bottom: 0rem !important;
		font-size: 2rem !important;
		line-height: 1;
	}
	.all-categories .row .card-body {
		border-bottom: 0 !important;
	}
	.ace-responsive-menu {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.item-card9-desc a,
	.item-card2-footer a,
	.item7-card-desc a {
		font-size: 12px;
	}
	.map-header1 {
		margin-bottom: 1.5rem !important;
	}
	.carousel-control-next {
		right: 0 !important;
	}
	.carousel-control-prev {
		left: 0 !important;
	}
	.app-btn a {
		width: 100%;
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.sub-newsletter {
		margin-bottom: 1rem;
		text-align: center;
		display: block !important;
	}
	.fea-ad .card,
	.cat-slide .card {
		margin-bottom: 1.5rem !important;
	}
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 85px !important;
	}
	.advertisment-block img {
		width: 100%;
	}
	.usertab-list li {
		width: 100% !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-left: 0 !important;
			}
		}
		.btn {
			margin-bottom: .3rem;
		}
	}
	.page-header .page-select {
		width: 35% !important;
	}
	.customerpage .card {
		margin-bottom: 1.5rem !important;
	}
	.ads-tabs .tabs-menus ul {
		li {
			a {
				padding: 8px 10px 8px 10px !important;
				text-align: center;
			}
			display: -webkit-box;
			text-align: center;
			margin-bottom: 1.5rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
		text-align: center;
	}
	.settings-tab .tabs-menu li a {
		border-right: 0 !important;
		border-bottom: 1px solid #e4e6f1;
	}
}

@media (max-width: 320px) {
	.product-item2-desc .label-rating {
		float: none !important;
		margin-top: 4px !important;
	}
}

@media (min-width: 569px) and (max-width: 767px) {
	.product-item2-desc .label-rating {
		float: none !important;
		margin-top: 4px !important;
	}
}

@media (max-width: 568px) {
	.items-blog-tab-heading .items-blog-tab-menu li {
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}
	.header-main .top-bar-right .custom {
		text-align: right;
		float: right;
		margin-bottom: 0 !important;
	}
	.settings-tab .tabs-menu li {
		width: 100% !important;
	}
	.top-bar .top-bar-right {
		float: none !important;
		text-align: center;
		display: block !important;
		margin: 0 auto;
	}
	.header-main .top-bar-right .custom li {
		margin-bottom: 0;
	}
	.top-bar .top-bar-left .socials li {
		border-left: 0 !important;
	}
	.header-main .top-bar-right .custom li {
		border-left: 0 !important;
		&:last-child {
			border-right: 0 !important;
		}
	}
	.item2-gl-nav {
		display: block !important;
		.item2-gl-menu li {
			text-align: center;
			margin-bottom: 10px;
			margin: 0 auto;
			padding: 10px 0;
		}
		label {
			display: block;
			float: none;
			text-align: center;
		}
	}
	.header-main .top-bar-right .custom li a span {
		display: none;
	}
	.top-bar .top-bar-left .socials li {
		float: left !important;
		margin: 0 !important;
	}
	.item2-gl {
		.item2-gl-nav select {
			width: 100% !important;
		}
		.item-card9-desc span {
			display: block;
			margin-bottom: 0.5rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.users-list {
		.page-header {
			padding: 10px !important;
		}
		.page-select .select2.select2-container {
			width: 100% !important;
			margin-bottom: 0.5rem;
		}
	}
}

@media (min-width: 569px) and (max-width: 992px) {
	.users-list {
		.select2.select2-container.select2-container--default {
			&.select2-container--focus,
			&.select2-container--below.select2-container--open {
				width: 150px !important;
			}
		}
		.page-select .select2.select2-container {
			width: 150px !important;
		}
	}
}

@media (min-width: 992px) and (max-width: 1300px) {
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 85px !important;
	}
}

@media (min-width: 569px) and (max-width: 992px) {
	.top-bar .top-bar-left .socials li:last-child {
		border-right: 1px solid #e4e6f1;
	}
}

@media (min-width: 481px) and (max-width: 992px) {
	.owl-product .owl-productimg {
		width: 100% !important;
		height: 100% !important;
		margin: 0 auto;
	}
	.art-wideget .art-details {
		margin-top: 10px;
	}
	.header-search .header-icons .header-icons-link1 .main-badge1 {
		top: -11px !important;
	}
	.user-wideget .widget-image {
		top: 50% !important;
	}
	.banner1 .header-text {
		h2 span,
		h3 span {
			padding: 0 !important;
		}
		top: 20% !important;
	}
	.item-card:hover .item-card-btn {
		left: 42%;
	}
	.product-img {
		margin: 0 auto;
		text-align: center;
	}
	.banner-2 img {
		height: 33rem;
	}
	.item-search-tabs {
		margin-top: 1rem !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-left: 0 !important;
			}
		}
		.btn {
			margin-bottom: .3rem;
		}
	}
	.wideget-user-tab {
		.tabs-menu1 ul li a {
			padding: 5px !important;
		}
		.tab-menu-heading .nav li a {
			font-size: 14px !important;
		}
	}
	.header-search .header-search-logo {
		margin-top: 5px;
	}
	.header-main .post-btn {
		margin-top: 3px;
	}
}

@media (min-width: 992px) and (max-width: 1024px) {
	.item-card .item-card-img img,
	.item-card2-img img,
	.item-card9-img img,
	.product-info .product-info-img img,
	.item-card4-img img,
	.item-card5-img img,
	.item-card7-img img,
	.item-card8-img img,
	.overview .overview-img img,
	.video-list-thumbs img {
		width: 100%;
	}
	.header-main .get-contact {
		a {
			border-left: 0 !important;
			padding-left: 0 !important;
		}
		margin-bottom: 15px;
	}
	.header-search .header-inputs {
		margin-bottom: 15px;
	}
	.header-main .social-icons {
		text-align: center;
		float: none !important;
		li:last-child {
			margin-right: 0;
		}
	}
	.header-search .header-icons {
		.header-icons-link {
			float: right;
		}
		float: none !important;
		text-align: center !important;
	}
	.top-bar {
		text-align: center;
		.top-bar-left {
			text-align: center;
			.contact {
				border-left: 0 !important;
				margin-left: 0 !important;
				padding-left: 0 !important;
			}
		}
		.top-bar-right {
			float: none !important;
			text-align: center;
			display: block !important;
			margin: 0 auto;
		}
		.top-bar-left .contact li:first-child {
			margin-right: 1.5rem !important;
		}
	}
	.product-multiinfo .product-ship p a:first-child,
	.product-singleinfo .product-ship p a:first-child {
		margin-bottom: 5px;
	}
	.banner-2 img {
		height: 27rem;
	}
	.items-blog-tab-heading .items-blog-tab-menu li {
		text-align: center;
		margin-bottom: 10px;
	}
	.ace-responsive-menu {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.header-main .top-bar-right .custom {
		display: -webkit-inline-box !important;
	}
	.icon-card li {
		font-size: 10px;
	}
	.support-service.bg-dark {
		margin-bottom: 10px;
	}
	.cat-slide .card,
	.fea-ad .card {
		margin-bottom: 1.5rem !important;
	}
	.advertisment-block img {
		width: 100%;
	}
	.header-main .post-btn {
		margin-top: 5px;
	}
	.customerpage .card {
		margin-bottom: 1.5rem !important;
	}
	.ads-tabs .tabs-menus ul li a {
		padding: 8px 10px 8px 10px !important;
	}
}

@media (max-width: 480px) {
	.item-all-card img.imag-service {
		width: 20% !important;
	}
	.section-title h1 {
		font-size: 2rem;
	}
	.item-all-cat .category-type .item-all-card img {
		width: 4rem !important;
		height: 4rem !important;
		padding: 1rem 0 !important;
	}
	.banner1 .header-text {
		h2 {
			span {
				padding: 0 !important;
			}
			font-size: 18px !important;
		}
		h3 {
			font-size: 14px !important;
			span {
				padding: 0 !important;
			}
		}
	}
	.card-blogs .card-item-desc.p-0 .card-item-wrap .footer-wrap-price {
		del {
			display: none;
		}
		span.h5 {
			font-size: 12px !important;
		}
	}
	.card-pay .tabs-menu li {
		width: 100% !important;
	}
	.header-search .header-icons .header-icons-link1 .main-badge1 {
		top: -19px !important;
	}
	.product-details .card-item-desc {
		display: none !important;
	}
	.art-wideget .art-details {
		margin-top: 10px;
	}
	.support .support-service {
		i {
			float: none !important;
			margin-right: 0 !important;
			margin-bottom: 20px;
		}
		text-align: center;
	}
	.banner-1 {
		height: 500px;
		
	}
	.item-card7-desc ul li,
	.item-card2-desc ul li {
		font-size: 12px;
	}
	.banner-2 img {
		height: 33rem;
	}
	.item-search-tabs {
		margin-top: 1rem !important;
	}
	.bannerimg .header-text {
		font-size: 1.5rem;
		.breadcrumb-item {
			font-size: 14px;
		}
	}
	.icons a {
		margin-bottom: .5rem;
	}
	.item-det ul li {
		font-size: 11px;
		margin-right: 0.5rem !important;
	}
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 60px !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-left: 0 !important;
			}
		}
		.btn {
			margin-bottom: .3rem;
		}
	}
}

@media (max-width: 992px) {
	.horizontal-header .container {
		position: relative;
	}
}

@media (max-width: 375px) {
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 49px !important;
	}
}

@media (max-width: 320px) {
	.product-slider #thumbcarousel .carousel-item .thumb {
		max-width: 40px !important;
	}
}

@media (max-width: 1336px) {
	.filter-product-info .table td {
		display: -webkit-box;
		padding: 3px !important;
	}
}

.all-categories .row {
	.card-body {
		border-bottom: 1px solid #e4e6f1;
	}
	&:last-child .card-body {
		border-bottom: 0 !important;
	}
}

.clear,
.current,
.ok {
	background: #e9ecfb;
	border-radius: 20px;
}

.today {
	background: #e9ecfb;
}

.graph canvas {
	width: 100% !important;
}

.trend {
	i {
		font-size: 10px !important;
		vertical-align: middle;
	}
	.media-body {
		margin-left: 10px;
	}
}

.datebox {
	top: 0;
	left: 0;
	border-radius: 2px;
	background: $white;
	padding: 0 15px 0 15px;
}

.iconbage .badge {
	position: absolute;
	top: 14px;
	right: 13px;
	padding: .2rem .25rem;
	min-width: 1rem;
	font-size: 13px;
}

.single-product .product-desc .product-icons .socialicons a {
	width: 2em;
	height: 2em;
	line-height: 2em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #eef2f9;
	color: $color;
	margin: 0 auto;
	text-align: center;
}

.blogimg {
	width: 100px;
}

.subnews img {
	width: 25%;
	height: 25%;
}

.task-img img,
.status-img img {
	width: 70px;
	height: 70px;
	margin: 0 auto;
}

.product-hover {
	position: relative;
	.product-info-buttons {
		position: absolute;
		display: none;
		text-align: center;
		top: 45%;
		margin: 0 auto;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
	}
	&:hover {
		&.product-hover:before {
			content: "";
			background: $black-5;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
		}
		.product-info-buttons {
			display: block;
			z-index: 1000;
		}
	}
}

.spacing {
	padding: 1.5rem;
}

.search-background,
.search1 {
	background: $white;
	border-radius: 2px;
	overflow: hidden;
}

.search2 {
	background: $white;
	padding: 30px;
	border-radius: 2px;
	overflow: hidden;
}

.search3 {
	background: $white;
	border-radius: 2px;
	a.btn {
		font-size: .8625rem;
	}
}

.product-single .product-thumbimg img {
	width: 130px;
	height: 130px;
}

.page-header .page-select {
	width: 20%;
}

.social li {
	float: left;
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 15px;
	float: right;
}

.social-icons li {
	a {
		color: $white;
	}
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 15px;
	width: 40px;
	height: 40px;
	margin: 1px;
	border-radius: 50%;
	border: 1px solid rgba(255,
	255,
	255,
	0.2);
	line-height: 40px;
}

.social li a {
	color: $white-6;
	line-height: 0;
}

.company-tags {
	a {
		float: left;
		margin-right: 5px;
		margin-bottom: 5px;
		padding: 5px 5px;
		border-radius: 2px;
		color: $black-5;
		font-size: 12px;
		font-weight: bold;
		border-bottom: 1px dotted #e4e6f1;
    	width: 100%;
		
		
		i {
			height: 16px;
		}
	}

	li a:hover {
		border-radius: 2px;
		color: $black-5 !important;
	}
}

.support-service {
	padding: 1.5rem 1.5rem;
	border: 1px solid #e4e6f1;
	background: #f2f3f8;
	i {
		float: left;
		margin-right: 15px;
		font-size: 1.5rem;
		line-height: 40px;
		width: 45px;
		text-align: center;
		height: 45px;
		border-radius: 50%;
		background: $white-3;
		border: 1px solid transparent;
	}
	h6 {
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 0;
	}
	p {
		margin-bottom: 0;
		font-size: 15px;
		color: $white-5;
	}
}

.counter-icon {
	margin-bottom: 1rem;
	display: inline-flex;
	width: 4rem;
	height: 4rem;
	padding: 1.3rem 1.4rem;
	border-radius: 50%;
	text-align: center;
	i {
		font-size: 1.2rem;
	}
}

.get-contact {
	a {
		color: #212529;
		margin-right: 15px;
		font-size: 16px;
		display: inline-block;
	}
	i {
		font-size: 18px;
	}
}

.filter-product-social {
	.social-icon2 li {
		color: #212529;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
	}
	.social-icons li a {
		color: #212529;
	}
}

.rated-products {
	.media {
		padding-bottom: 15px;
		border-bottom: 1px solid #e4e6f1;
		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
		img {
			width: 80px;
			height: 80px;
		}
	}
	img {
		border: 1px solid #e4e6f1;
		padding: 5px;
		background: #f6f7fb;
	}
}

.product-info-img a {
	position: absolute;
	top: 35px;
	right: 30px;
}

.product-filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin-bottom: 15px;
	.product-filter-img {
		width: 60px;
		height: 60px;
	}
}

.product-filter-desc .product-filter-icons a {
	width: 2.5em;
	height: 2.5em;
	line-height: 2.4em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	&:hover {
		color: $white;
	}
}

.usertab-list {
	display: inline-block;
	li {
		width: 50%;
		margin-bottom: .6rem;
		float: left;
	}
}

.item-box {
	.item-box-wrap {
		margin-top: 15px;
	}
	.stamp i {
		font-size: 18px;
		font-size: 25px;
		line-height: 2;
	}
	.item-box-wrap h5 {
		font-size: 20px;
		font-weight: 600;
	}
}

.info-box small {
	font-size: 14px;
}

.info-box-icon {
	display: block;
	float: left;
	height: 90px;
	width: 90px;
	text-align: center;
	font-size: 45px;
	line-height: 90px;
	background: $black-2;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.info-box {
	display: block;
	min-height: 90px;
	background: $white;
	width: 100%;
	border-radius: 2px;
	margin-bottom: 15px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}

.info-box-icon>img {
	max-width: 100%;
}

.info-box-content {
	padding: 1.5rem 1.5rem;
	margin-left: 90px;
}

.info-box-number {
	display: block;
}

.info-box-text {
	display: block;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}

.info-box-more {
	display: block;
}

.product-item {
	img {
		height: 180px;
		width: 180px;
	}
	span {
		top: 15px;
		left: 15px;
		position: absolute;
	}
}

.product-item-wrap .product-item-price {
	.newprice {
		font-size: 18px;
		font-weight: 600;
	}
	.oldprice {
		margin-left: 5px;
		font-size: 18px;
		font-weight: 600;
	}
}

.product-item1 {
	position: relative;
	.item-overlay {
		border-radius: .2rem .2rem 0 0;
		overflow: hidden;
		padding: 15px;
		text-align: center;
		img {
			height: 200px;
			width: 200px;
		}
	}
	.btn-overlay {
		position: absolute;
		display: none;
		text-align: center;
		top: 45%;
		margin: 0 auto;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
		z-index: 1;
	}
	&:hover {
		&.product-item1:before {
			content: "";
			background-color: $white-8;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
		.btn-overlay {
			display: block;
			z-index: 1;
			opacity: 1;
		}
	}
}

.product-item2 .product-item2-img {
	img {
		height: 200px;
		width: 200px;
	}
	padding: 1.5rem 1.5rem;
}

.product-item2-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}

.product-item2 {
	border-bottom: 1px solid #e4e6f1;
	background: #e7e9f1;
}

.owl-productimg img {
	background: #e7e9f1;
	padding: 20px;
}

.product-item2-desc .label-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
}

.product-details {
	.media {
		margin-top: 0;
	}
	.card-item-desc {
		padding: 0;
		margin-left: 15px;
		margin-top: 8px;
	}
}

.product-singleinfo .product-item2-align dt,
dd {
	display: inline-block;
}

.product-singleinfo {
	.product-item2-align {
		dd {
			margin-left: 2rem;
			margin-bottom: 0;
			vertical-align: baseline;
		}
		dt {
			width: 100px;
			float: left;
		}
	}
	dl {
		margin-bottom: 5px;
	}
}

.oldprice {
	font-size: 25px;
	font-weight: 600;
}

.product-singleinfo .product-ship .product-item-price {
	.newprice {
		font-size: 25px;
		font-weight: 600;
	}
	.oldprice {
		margin-left: 5px;
	}
}

.product-multiinfo {
	.card {
		box-shadow: none;
		margin-bottom: 0;
		border-bottom: 1px solid #e4e6f1;
	}
	.product-item img {
		width: 120px;
		height: 120px;
	}
}

.single-productslide .product-gallery .product-item {
	margin-bottom: 10px;
	overflow: hidden;
	background-color: $white;
}

.product-gallery {
	.product-item img {
		height: 350px;
		width: auto;
		display: inline-block;
		cursor: -webkit-zoom-in;
		cursor: zoom-in;
	}
	.product-sm-gallery {
		text-align: center;
		.item-gallery {
			img {
				max-width: 100%;
				max-height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				border-radius: 2px;
				cursor: -webkit-zoom-in;
				cursor: zoom-in;
			}
			width: 80px;
			height: 80px;
			border: 1px solid #e4e6f1;
			padding: 5px;
			display: inline-block;
			overflow: hidden;
			line-height: 4.5;
		}
	}
}

.single-productslide .product-gallery-data {
	padding: 30px;
}

.product-gallery-data .product-gallery-data1 dt,
dd {
	display: inline-block;
}

.product-gallery-data {
	dl {
		margin-bottom: 5px;
	}
	.product-gallery-data1 {
		dt {
			width: 100px;
			float: left;
		}
		dd {
			margin-left: 2rem;
			margin-bottom: 0;
			vertical-align: baseline;
		}
	}
}

.product-gallery-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	font-size: 18px;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}

.product-gallery-rats {
	margin-top: 20px;
}

.product-gallery-data {
	.label-rating {
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
	}
	.product-gallery-quty dt {
		display: inline-block;
	}
}

dd,
.product-gallery-data .product-gallery-size dt,
dd {
	display: inline-block;
}

.product-gallery-data .product-gallery-size .product-gallery-checks label {
	display: inline-block;
	margin-left: 10px;
}

.product-card-img img {
	height: 180px;
	width: 180px;
}

.product-card-icons {
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	display: inline-block;
}

.product-card-footer .product-footer-wrap .footer-wrap-price {
	font-size: 16px;
	font-weight: 600;
	del {
		margin-left: 5px;
	}
}

.product-card4-footer .product-card4-wrap .product-card4-price h6,
.product-card5-footer .product-card5-price h6 {
	font-size: 25px;
	font-weight: 600;
}

.product-card6-footer {
	.product-card6-price h6 {
		font-size: 25px;
		font-weight: 600;
	}
	.product-card6-info del {
		margin-right: 5px;
	}
}

.product-card7-footer .product-card7-price {
	h6 {
		font-size: 25px;
		font-weight: 600;
	}
	del,
	span {
		margin-left: 5px;
	}
}

.team-section .team-img img {
	max-width: 80px;
	box-shadow: none;
}

.item-single .item-single-desc .item-single-desc-list .listunorder {
	border: 0;
	padding: 5px;
	margin-bottom: 0;
	font-size: 15px;
}

.checklist-task .checklist-desc .check-data {
	span {
		font-size: 13px;
	}
	.check-icon {
		margin-top: 0;
	}
}

.banner1 {
	.carousel-inner img {
		width: 100%;
		max-height: 460px;
	}
	.carousel-item:before {
		content: '';
		display: block;
		position: absolute;
		background: $black-6;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
	.carousel-control {
		width: 0;
		&.left,
		&.right {
			opacity: 1;
			background-image: none;
			background-repeat: no-repeat;
			text-shadow: none;
		}
		&.left span,
		&.right span {
			padding: 15px;
		}
		.glyphicon-chevron-left,
		.glyphicon-chevron-right,
		.icon-next,
		.icon-prev {
			position: absolute;
			top: 45%;
			z-index: 5;
			display: inline-block;
		}
		.glyphicon-chevron-left,
		.icon-prev {
			left: 0;
		}
		.glyphicon-chevron-right,
		.icon-next {
			right: 0;
		}
		&.left span,
		&.right span {
			background: $white-1;
			color: $white;
		}
		&.left span:hover,
		&.right span:hover {
			background: $white-3;
		}
	}
	.header-text {
		position: absolute;
		left: 0;
		right: 0;
		color: $white;
	}
	.slider .header-text {
		top: 29%;
	}
	.header-text {
		h2 {
			font-size: 40px;
		}
		h3 {
			font-size: 25px;
		}
		h2 span {
			padding: 10px;
		}
		h3 span {
			padding: 15px;
		}
	}
}

@media (max-width: 480px) {
	.banner1 {
		.slider {
			.header-text {
				top: 30% !important;
			}
			img {
				height: 400px;
			}
		}
		&.slider-images .header-text {
			top: 9.5% !important;
		}
	}
	.sptb-2 {
		padding-top: 0 !important;
		padding-bottom: 3rem !important;
	}
	.sptb-tab.sptb-2.pt-10 {
		padding-top: 6.5rem !important;
	}
	.banner-2.sptb-2 {
		padding-top: 0 !important;
		padding-bottom: 7rem !important;
	}
	.banner-section .header-text {
		top: 50px !important;
	}
}

@media (min-width: 481px) and (max-width: 767px) {
	.banner1 .slider {
		.header-text {
			top: 35% !important;
		}
		img {
			height: 348px;
		}
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.banner1 .slider .header-text {
		top: 32% !important;
	}
}

@media (min-width: 993px) and (max-width: 1240px) {
	.banner1 .slider .header-text {
		top: 23% !important;
	}
}

.input-indec {
	.quantity-left-minus.btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0;
	}
	.quantity-right-plus.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}
}

.cat-item {
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.cat-img {
		width: 4rem;
		height: 4rem;
		margin: 0 auto;
		padding: 16px;
		border-radius: 50%;
	}
	.cat-imgs img {
		width: 40px;
		height: 40px;
		margin: 0 auto;
	}
	.cat-desc {
		margin-top: 12px;
		color: #212529;
	}
	.cat-icon {
		width: 60px;
		height: 60px;
		padding: 18px;
		margin: 0 auto;
		text-align: center;
		i {
			font-size: 25px;
		}
	}
}

.business-item .item-card .item-card-desc .item-card-text {
	top: 40%;
}

@media (max-width: 1300px) and (min-width: 400px) {
	.classifieds-content .item-search-menu ul li a,
	.item-search-menu ul li a {
		display: block;
		margin: 3px;
		border-radius: 2px;
	}
}

.accent-2 {
	width: 60px;
	border-top: 2px solid $white-2;
}

.Marketplace {
	.item-card7-imgs:before {
		background: rgba(25, 36, 79, 0.1);
	}
	.item-card7-overlaytext h4 {
		font-size: 18px;
		border-radius: 2px;
		padding: 7px 12px;
		text-align: center;
		bottom: 5px;
		right: 5px;
		background: $black-8;
	}
}

.product-slider {
	#carousel {
		margin: 0;
	}
	#thumbcarousel {
		margin: 10px 0 0 0;
		padding: 0;
		.carousel-item {
			text-align: center;
			.thumb {
				width: 100%;
				margin: 0 2px;
				display: inline-block;
				vertical-align: middle;
				cursor: pointer;
				max-width: 137px;
				&:hover {
					border-color: #e4e6f1;
				}
			}
		}
	}
	.carousel-item img {
		width: 100%;
		height: auto;
	}
}

#thumbcarousel {
	.carousel-control-prev {
		left: 0;
		background: $black-5;
	}
	.carousel-control-next {
		right: 0;
		background: $black-5;
	}
}

.ace-responsive-menu {
	margin-right: 1rem;
}

.sptb {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.sptb-1 {
	padding-top: 8.5rem;
	padding-bottom: 4.5rem;
}

.sptb-2 {
	padding-top: 4rem;
	padding-bottom: 10rem;
}

.sptb-3,
.sptb-4 {
	padding-top: 6.2rem;
	padding-bottom: 11rem;
}

.sptb-8 {
	padding-top: 7rem;
	padding-bottom: 12rem;
}

.categories {
	margin-top: -65px;
	margin-bottom: 65px;
}

.categories-1 {
	margin-top: -120px;
}

.section-title {
	p {
		text-align: center;
		font-size: 16px;
		margin-bottom: 0;
	}
	padding-bottom: 2rem;
	h1 {
		margin-bottom: .5rem;
		color: $color;
		font-size: 36px;
		font-weight: 400;
		z-index: 1;
	}
}

@media (min-width: 992px) {
	.select2-container .select2-selection--single {
		border-radius: 0 !important;
	}
	.slider-images img {
		height: 440px !important;
		background: cover;
		left: 0;
		right: 0;
		width: 100% !important;
	}
	.banner1 .slider .header-text {
		top: 29%;
	}
	.input-field {
		margin-top: 7.55rem !important;
	}
	.classified-video {
		margin-top: 2.1rem;
	}
	.sticky-wrapper.is-sticky .horizontal-main {
		.horizontalMenu>.horizontalMenu-list>li {
			padding: 0.75rem 0;
		}
		.btn {
			margin-top: 12px;
		}
	}
	.horizontal-main .btn {
		margin-top: 8px;
	}
}

@media (max-width: 991px) {
	.select2-container .select2-selection--single {
		border-radius: 2px !important;
	}
	.sptb-4,
	.sptb-3 {
		padding-top: 0rem;
		padding-bottom: 10rem;
	}
	.banner-1 .item-search-tabs .search-background .form-control {
		border-radius: 2px !important;
		border-bottom: 1px !important;
	}
	.item-card9-img {
		height: auto !important;
	}
	.blog-list-1 {
		height: 100% !important;
	}
	.banner-1 .search-background {
		.form-control {
			border-radius: 2px !important;
		}
		.form1 {
			border-radius: 0;
			border-left: 1px solid #e4e6f1 !important;
		}
	}
	.item-search-tabs a.btn {
		border-top-left-radius: 2px !important;
		border-bottom-left-radius: 2px !important;
		border-radius: 2px !important;
		right: 0;
	}
	.content-text .display-5 {
		font-size: 1.8rem;
	}
	.counter-status.status {
		margin-top: 1.5rem;
	}
	.margin-top {
		margin-top: 4.5rem !important;
	}
	.desktoplogo-1 {
		display: none;
	}
	.sptb-1 {
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
	}
	.search-background {
		background: 0 0 !important;
		border-radius: 0;
	}
	.padding-bottom {
		padding-bottom: 0 !important;
	}
	.banner-2 .form-control,
	.item-search-tabs .form-control.border {
		border-radius: 2px !important;
	}
	.banner-1 .search-background .btn-lg {
		border-radius: 2px !important;
		right: 0 !important;
	}
	.banner1 .slide-header-text {
		top: 8.5% !important;
	}
	.sptb-2 {
		padding-top: 0;
		padding-bottom: 9rem;
	}
	.sticky-wrapper.is-sticky {
		.horizontal-main {
			background: 0 0;
		}
		.desktoplogo-1 {
			display: none !important;
		}
	}
	.bannerimg {
		padding: 3rem 0 3rem 0 !important;
		background-size: cover;
	}
	.sticky-wrapper {
		height: 0 !important;
	}
	.select2.select2-container.select2-container--default.select2-container--focus {
		width: 100% !important;
		overflow: hidden;
	}
	.horizontalMenu>.horizontalMenu-list>li>a.active {
		background-color: #e67605;
		color: $white;
	}
	.banner1.relative.slider-images .owl-carousel .owl-item img {
		display: block;
		height: 450px;
	}
	#main section>.text {
		top: 48% !important;
	}
}

.banners-image .horizontalMenu>.horizontalMenu-list>li>a {
	color: $color !important;
}

.sticky.sticky-pin.sticky-wrapper.absolute.banners .horizontal-main {
	background: $white !important;
}

.sticky-wrapper.absolute.banners {
	.horizontal-main {
		.horizontalMenu>.horizontalMenu-list>li>a {
			color: $color !important;
			>.fa {
				color: $color !important;
			}
		}
		&:after {
			content: '';
			position: absolute;
			height: 3px;
			border-top: 0;
			background: #c49cde;
			border-radius: 2px;
			z-index: 3;
			width: 100%;
			bottom: 0;
			background-image: -moz-linear-gradient(to right, #4801ff, #a36bc7 12.5%, #20c981 12.5%, #2dce89 25%, #e67605 25%, #fecf71 37.5%, #e45a4d 37.5%, #f8b5ae 50%, #e67605 50%, #fa74a1 62.5%, #8b51b0 62.5%, #c49cde 75%, #1874f0 75%, #92bbf4 87.5%, #1d8eb6 87.5%, #89d3ee);
		}
	}
	.horizontalMenu>.horizontalMenu-list>li {
		&:hover>a,
		>a.active {
			background-color: #e67605;
			color: $white !important;
			.fa {
				color: $white !important;
			}
		}
	}
}

.banner-1 {
	.item-search-tabs .search-background .form-control {
		border-radius: 0;
		border-bottom: 0 !important;
	}
	.search-background {
		.form-control {
			border-radius: 0;
		}
		.form1 {
			border-radius: 0;
			border-left: 1px solid #e4e6f1 !important;
		}
		.btn-lg {
			position: relative;
		}
		.select2-container--default .select2-selection--single {
			border-radius: 0;
		}
	}
	.header-text,
	.header-text1 {
		left: 0;
		right: 0;
		color: $white;
	}
	.header-text {
		h1 {
			margin-bottom: .3rem;
			font-weight: 400;
		}
		p {
			margin-bottom: 1.5rem;
			font-size: 16px;
		}
	}
}

.banner-2 {
	.form-control {
		border-radius: 0;
		border: 0 !important;
	}
	.header-text {
		left: 0;
		right: 0;
		color: $white;
		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}
}

.bannerimg {
	padding: 6.5rem 0 3rem 0;
	background-size: cover;
	.header-text h1 {
		margin-bottom: .75rem;
		font-size: 2.5rem;
	}
}

.about-1 {
	position: relative;
	.header-text {
		left: 0;
		right: 0;
		color: $white;
	}
}

.country .dropdown-menu {
	height: 233px;
	overflow: scroll;
}

.product-slider {
	.carousel-control-next i,
	.carousel-control-prev i {
		font-size: 2rem;
		padding: .5rem;
	}
}

.test-carousel {
	.carousel-control-next i,
	.carousel-control-prev i {
		font-size: 2rem;
		color: $black;
		padding: .5rem;
	}
}

.support-list li {
	a {
		margin-bottom: .5rem;
		display: flex;
	}
	i {
		margin-right: .7rem;
		margin-top: 4px;
	}
}

.profile-pic-img {
	position: relative;
	width: 5rem;
	height: 5rem;
	line-height: 5rem;
	font-size: 2rem;
	text-align: center;
	margin: 0 auto;
	span {
		position: absolute;
		width: 1rem;
		height: 1rem;
		border-radius: 50px;
		right: -1px;
		top: .5rem;
		border: 2px solid $white;
	}
}

.sub-newsletter {
	display: inline-block;
}

.user-tabel span {
	margin: 0 auto;
}

.settings-tab .tabs-menu {
	margin-bottom: 25px;
	li {
		width: 33.3%;
		display: block;
		a {
			padding: .5rem 1rem;
			background: #edeff7;
			color: $black;
			display: block;
			text-align: center;
			border-right: 1px solid #e4e6f1;
			&.active {
				color: $white;
				border-radius: 2px;
				text-align: center;
			}
		}
	}
}

.ads-tabs .tabs-menus {
	margin-bottom: 2rem;
	ul li {
		a {
			border: 1px solid #e4e6f1;
			color: $color;
			padding: 10px 20px 11px 20px;
			border-radius: 2px;
		}
		margin-right: 1rem;
		&:last-child {
			margin-right: 0;
		}
	}
}

.select-languages {
	color: $color;
	border-radius: 2px;
	&:focus {
		color: #3d4e67;
		background-color: $white;
	}
}

.img-flag {
	width: 42px;
    height: 21px;
    margin-top: -4px;
}

#price {
	border: 0;
	font-weight: 500;
	font-size: 18px;
}

.showmore-button {
	text-align: center;
}

.showmore-button-inner {
	display: inline-block;
	padding: 10px 19px;
	line-height: 14px;
	font-size: 14px;
	border-radius: 25px;
	text-decoration: none;
	cursor: pointer;
	margin-top: 20px;
}

.showmore-button1 {
	text-align: center;
	.showmore-button1-inner {
		display: inline-block;
		line-height: 14px;
		font-size: 14px;
		color: #6e82a9;
		text-decoration: none;
		cursor: pointer;
		margin-top: 8px;
		margin-bottom: 20px;
	}
}

.fea-ad .card,
.cat-slide .card {
	margin-bottom: 0;
}

.fade.in {
	opacity: 1;
}

.owl-carousel {
	position: relative;
	.owl-item {
		position: relative;
		cursor: url(../../../assets/plugins/owl-carousel/cursor.png), move;
		overflow: hidden;
	}
}

.owl-nav {
	display: block;
}

#small-categories .owl-nav {
	.owl-next,
	.owl-prev {
		top: 58%;
	}
}

.slider .owl-nav {
	.owl-prev {
		left: 15px;
	}
	.owl-next {
		right: 15px;
	}
}

.owl-nav {
	.owl-prev {
		position: absolute;
		top: 50%;
		left: -25px;
		right: -1.5em;
		margin-top: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 50%;
		right: -25px;
		margin-top: -1.65em;
	}
	button {
		display: block;
		font-size: 1.3rem !important;
		line-height: 2em;
		border-radius: 50%;
		width: 3rem;
		height: 3rem;
		text-align: center;
		background: $white-5 !important;
		border: 1px solid #e4e6f1 !important;
		z-index: 99;
		box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
		&:before {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #2098d1;
			-webkit-transform: scaleY(0);
			transform: scaleY(0);
			-webkit-transform-origin: 50% 0;
			transform-origin: 50% 0;
			-webkit-transition-property: transform;
			transition-property: transform;
			-webkit-transition-duration: .5s;
			transition-duration: .5s;
			-webkit-transition-timing-function: ease-out;
			transition-timing-function: ease-out;
		}
	}
}

@media (max-width: 480px) {
	.owl-nav {
		.owl-prev {
			left: -10px;
		}
		.owl-next {
			right: -10px;
		}
	}
	.countdown-timer-wrapper {
		.timer .timer-wrapper .time {
			width: 70px;
			height: 70px;
			font-size: 1.3rem !important;
			margin-bottom: -25px;
		}
		margin: 39px auto 15px auto !important;
	}
	.categories-1 .banner-1 .header-text p {
		margin-bottom: .5rem;
		font-size: 14px;
	}
}

.owl-carousel:hover .owl-nav button {
	background: rgb(255, 255, 255) !important;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	animation: sonarEffect 1.3s ease-out 75ms;
}

.owl-nav>div i {
	margin: 0;
}

.owl-theme .owl-dots {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	bottom: .65em;
	left: 0;
	right: 0;
	z-index: 99;
	.owl-dot {
		display: inline-block;
		zoom: 1;
		span {
			width: 1em;
			height: 1em;
			margin: 5px 7px;
			background: $black-3;
			display: block;
			-webkit-backface-visibility: visible;
			transition: opacity .2s ease;
			border-radius: 30px;
		}
	}
}

.testimonial-owl-carousel img {
	margin: 0 auto;
	text-align: center;
}

.owl-carousel.owl-drag .owl-item {
	left: 0 !important;
	right: 0;
}

.eductaional-tabs.nav {
	display: flex;
	justify-content: center;
	flex-direction: row;
}

@media (max-width: 767px) {
	.tabs-menu ul.eductaional-tabs li {
		width: 50%;
		text-align: center;
	}
	.icon-card li {
		&.mb-0 {
			&:last-child {
				margin-bottom: 0 !important;
			}
			margin-bottom: 0.5rem !important;
		}
		width: 100% !important;
	}
	.item-cards7-ic li {
		width: 100% !important;
	}
	.item-card7-desc ul.d-flex li {
		margin-bottom: 10px;
		width: 100% !important;
	}
	.counter-status.status-1 {
		margin-top: 1.5rem;
	}
	.item-card7-desc ul.d-flex {
		display: block !important;
	}
	.tabs-menu ul.eductaional-tabs li a {
		display: block;
	}
	.item-card2-desc ul.d-flex li {
		margin-bottom: 10px;
	}
	.item7-card-desc.d-flex,
	.item-card2-desc ul.d-flex {
		display: block !important;
	}
	.item2-gl .item-card9-img.doctor-details img {
		height: auto !important;
	}
}

.horizontalMenu>.horizontalMenu-list>li>a.btn:hover {
	background: #fb9512;
}

.profile-pic {
	text-align: center;
	.d-md-flex {
		text-align: left;
	}
}

@media (max-width: 480px) {
	.d-flex.ad-post-details {
		display: block !important;
	}
	.tabs-menu1 ul li a {
		margin: 2px;
	}
	.list-id .col {
		display: block;
		width: 100%;
	}
	.ads-tabs .tabs-menus ul li {
		a {
			width: 100%;
			display: block;
		}
		margin-bottom: 10px;
		margin-right: 0;
		width: 100%;
	}
	.zoom-container .arrow-ribbon2 {
		font-size: 20px;
		&:before {
			right: -17px;
		}
	}
}

.owl-carousel {
	.owl-dots {
		margin: 0 auto;
		text-align: center;
	}
	button.owl-dot {
		margin: 10px 10px 0 10px;
		border-radius: 50%;
		width: 10px;
		height: 10px;
		text-align: center;
		display: inline-block;
		border: none;
		background: #dee0ec !important;
	}
}

.owl-carousel-icons5 .owl-nav {
	.owl-prev {
		position: absolute;
		top: 42%;
		left: auto;
		right: -24px;
		margin-top: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 58%;
		left: auto;
		right: -24px;
		margin-top: -1.65em;
	}
}

.owl-carousel-icons4.owl-carousel .owl-item img {
	margin: 0 auto;
}

#carousel-controls.owl-carousel .owl-item img,
#exzoom {
	width: 100%;
}

:focus {
	outline: 0 !important;
}

.marketplace-or {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	font-size: 20px;
	line-height: 56px;
	text-align: center;
	margin: 0 auto;
	border: 3px solid rgba(255, 162, 43, 0.1);
	background: rgba(255, 162, 43, 0.3);
}

.marketplace-alignment {
	.text-center {
		margin: 0 auto;
		text-align: center;
		display: block;
	}
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.ecommerce-menu {
	float: none !important;
	&.horizontalMenu>.horizontalMenu-list>li>a {
		padding: 9px 30px;
		.fa {
			margin-right: 9px;
		}
	}
}

@media (max-width: 340px) {
	.auction-content .product-item2-desc .product-item2-rating {
		display: block;
	}
}

@media (max-width: 767px) and (min-width: 569px) {
	.auction-content .product-item2-desc .product-item2-rating {
		display: block;
	}
}

@media (max-width: 1024px) and (min-width: 992px) {
	.auction-content .product-item2-desc .product-item2-rating,
	.owl-carousel-icons6 .product-item2-desc .product-item2-rating {
		display: block;
	}
}

@media (min-width: 992px) and (max-width: 1350px) {
	.ecommerce-menu.horizontalMenu>.horizontalMenu-list>li>a {
		padding: 9px 15px;
	}
}

@media (min-width: 568px) and (max-width: 767px) {
	#container1 {
		min-height: 460px !important;
	}
}

@media (max-width: 567px) {
	#container1 {
		min-height: 515px !important;
	}
}

@media (max-width: 992px) {
	.header-menu1 {
		.input-group {
			padding: 10px !important;
		}
		a.btn {
			width: 220px;
			text-align: center !important;
			margin: 0 auto !important;
			float: none !important;
		}
	}
	.testimonial-owl-carousel .owl-nav {
		.owl-prev,
		.owl-next {
			top: 20% !important;
		}
	}
	.item2-gl .item2-gl-nav .select2.select2-container.select2-container--default {
		&.select2-container--focus,
		&.select2-container--below.select2-container--open {
			width: 150px !important;
		}
	}
}

.register-right .nav-tabs {
	.nav-link {
		padding: 12px 25px;
		margin: 1px;
		text-align: center;
		display: block;
		border-radius: 2px;
		border: 1px solid transparent;
	}
	background: $white;
	margin: 0 auto;
	border-radius: 2px;
	border: 1px solid #e4e6f1;
	box-shadow: none;
	.nav-item {
		display: block;
		text-align: center;
	}
}

.banner-section .header-text {
	position: relative;
	z-index: 10;
	top: 70px;
	bottom: 70px;
	h1 {
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 2.5rem;
	}
}

#main section>.text h1 {
	font-family: 'Poppins', sans-serif;
	font-weight: 400 !important;
	font-size: 2.5rem !important;
}

.section-title h2 {
	font-family: 'Poppins', sans-serif;
	font-size: 1.8rem;
	font-weight: 400;
}

@media (max-width: 320px) {
	.Marketplace .tab-pane .card-footer .footerimg-r {
		display: none;
	}
	.slider-images .slide-header-text h1 {
		font-size: 1.7rem !important;
	}
}

#homeVideo button.btn.btn-default {
	background: rgba(34, 40, 74, 0.7);
	position: absolute;
	right: 5px;
	top: 5px;
	border-radius: 25px;
	padding: 6px 3px;
	line-height: 25px;
	z-index: 5;
	color: $white;
	&:hover {
		background: rgb(34, 40, 74);
	}
}

.vertical-scroll {
	.news-item {
		border: 1px solid #e4e6f1 !important;
		margin-bottom: 15px;
		padding: 10px;
		td:last-child {
			width: 76%;
		}
	}
	.item {
		border: 1px solid #e4e6f1 !important;
		margin-bottom: 5px;
		margin-top: 5px;
		.p-5 {
			padding: 1rem !important;
		}
	}
	margin-bottom: 0;
}

.vertical-scroll1 .item {
	border: 1px solid #e4e6f1 !important;
	margin-bottom: 15px;
	background: $white;
	a {
		text-decoration: intial;
	}
}

.vertical-scroll .item2 {
	border-bottom: 1px solid #e4e6f1 !important;
	padding: 12px 25px;
}

@media (max-width: 480px) {
	.employers-details {
		display: none;
	}
	.hide-details {
		height: 128px !important;
	}
}

@media (max-width: 400px) {
	.employers-btn,
	.distracted {
		display: none;
	}
}

.pt-10 {
	padding-top: 8rem !important;
}

@media (max-width: 480px) {
	.reviews-categories .widget-spec1 li {
		margin-bottom: .5rem;
		font-size: .9rem;
		display: flow-root;
		span {
			margin: 0;
		}
	}
}

@media (max-width: 767px) {
	.books-categories.item-all-cat .row .item-all-card,
	.doctor-categories.item-all-cat .row .item-all-card {
		margin-bottom: .5rem;
	}
	.rating-stars .rating-stars-container .rating-star.sm {
		font-size: 13px;
	}
}

@media (max-width: 568px) {
	.items-gallery .items-blog-tab-heading {
		padding: 15px 0;
	}
	.items-blog-tab-heading .items-blog-tab-menu li a {
		margin-left: 0;
		margin-right: 0;
	}
	.items-gallery .items-blog-tab-heading {
		display: block;
	}
	.books-categories.item-all-cat .row .item-all-card {
		margin-bottom: .5rem;
	}
}

.horizontalMenu>.horizontalMenu-list,
.horizontal-header {
	background-color: transparent;
}

.horizontalMenu>.horizontalMenu-list>li {
	>a {
		color: $color;
		&.btn {
			color: $white;
			font-size: .85rem;
		}
		>.fa,
		&.active .fa {
			color: $white;
		}
	}
	&:hover>a>.fa {
		color: $white;
	}
	> {
		a:hover .fa {
			color: $white;
		}
		ul.sub-menu>li a i {
			color: #333;
		}
	}
}

code {
	font-size: 16px;
	background: #f1f2f7;
	padding: 3px 5px;
	border-radius: 2px;
	border: 1px solid #e4e6f1;
	color: inherit;
	word-break: break-word;
}

button#ihavecookiesBtn {
	margin-left: 0 !important;
}

#gdpr-cookie-message {
	position: fixed;
	z-index: 999;
	left: 0;
	bottom: 30px;
	max-width: 375px;
	background-color: $white;
	padding: 20px;
	border-radius: 2px;
	border: 1px solid #e4e6f1;
	box-shadow: 0px 16px 20px 0px rgba(216, 216, 216, 0.2);
	margin-left: 30px;
	text-align: center;
	display: none;
	h4 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	h5 {
		font-size: 15px;
		font-weight: 500;
		margin-bottom: 10px;
	}
	p,
	ul {
		color: $color;
		font-size: 15px;
		line-height: 1.5em;
	}
	p:last-child {
		margin-bottom: 0;
		text-align: center;
	}
	li {
		width: 49%;
		display: -webkit-inline-box;
	}
	a {
		text-decoration: none;
		font-size: 15px;
		padding-bottom: 2px;
		transition: all .3s ease-in;
	}
	button {
		border: none;
		color: $white;
		font-size: 15px;
		padding: 7px 18px;
		border-radius: 2px;
		margin-top: 4px;
		cursor: pointer;
		transition: all .3s ease-in;
	}
}

button {
	&#ihavecookiesBtn {
		border: none;
		color: $white;
		font-size: 15px;
		padding: 7px 18px;
		border-radius: 2px;
		margin-top: 4px;
		cursor: pointer;
		transition: all .3s ease-in;
	}
	&#gdpr-cookie-advanced {
		margin-left: 4px;
	}
}

@media (max-width: 390px) {
	button#gdpr-cookie-advanced {
		margin-left: 0 !important;
	}
	#gdpr-cookie-message p button {
		width: 100%;
	}
}

@media (max-width: 480px) {
	#gdpr-cookie-message {
		margin: 0 15px;
		display: block;
		right: 0;
		box-shadow: 0 0 20px 0 rgba(40, 37, 89, 0.9);
		display: none !important;
	}
}

#gdpr-cookie-message {
	button:disabled {
		opacity: .3;
	}
	input[type=checkbox] {
		float: none;
		margin-top: 0;
		margin-right: 5px;
	}
}

.marketplace-section img {
	width: 7rem;
	height: 7rem;
	border-radius: 50%;
	padding: 2.3rem 0;
}

.ui-widget-header {
	border: 1px solid #2c4359;
	color: #e1e463;
	font-weight: 700;
	a {
		color: #e1e463;
	}
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	border: 1px solid #e4e6f1;
	font-weight: 700;
	color: #333;
}

#particles-js {
	position: absolute;
	height: 100%;
	left: 0;
	right: 0;
	width: 100%;
	top: 0;
	bottom: 0;
}

h1.animated-text {
	font-family: monospace;
	overflow: hidden;
	border-right: .15em solid orange;
	white-space: nowrap;
	margin: 0 auto;
	letter-spacing: .15em;
	animation: typing 3.5s steps(30, end), blinking-cursor 0.5s step-end infinite;
}

@keyframes typing {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}

@keyframes blinking-cursor {
	from,
	to {
		border-color: transparent;
	}
	50% {
		border-color: orange;
	}
}

.banner1 .slide-header-text {
	top: 9%;
	z-index: 98 !important;
}

.slider-header .item:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	display: block;
	z-index: 1;
	top: 0;
}

a.typewrite {
	color: $white !important;
	font-weight: 400;
	font-size: 2.5rem;
	font-family: 'Poppins', sans-serif;
}

.user-pattern {
	background: url(../../../assets/images/pngs/pattern.png);
}

.pattern {
	background: url(../../../assets/images/media/photos/pattern1.jpg);
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		display: block;
		z-index: 0;
		top: 0;
	}
}

.pattern1 {
	background: url(../../../assets/images/media/photos/pattern.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		display: block;
		z-index: 0;
		top: 0;
	}
}

.pattern-1 {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	background-size: cover;
}

.single-page {
	button.dark {
		border-color: #ff4931;
		background: #ff4931;
	}
	.move button.dark {
		border-color: #e0b722;
		background: #e0b722;
	}
	.splits p {
		font-size: 18px;
	}
	button {
		&:active {
			box-shadow: none;
		}
		&:focus {
			outline: 0;
		}
	}
	>.wrapper3 {
		width: 100%;
		padding-top: 8rem;
	}
	.wrapper {
		&.wrapper2 {
			background: $white;
			width: 100%;
			background: $white;
			transition: all .5s;
			color: $white;
			overflow: hidden;
			border-radius: 2px;
			text-align: center;
			top: -37%;
			left: 4%;
		}
		background: $white;
		box-shadow: none;
		transition: all .5s;
		color: #838ab6;
		overflow: hidden;
		border-radius: 2px;
		border: 1px solid #e4e6f1;
		>form {
			width: 100%;
			transition: all .5s;
			background: $white;
			width: 100%;
		}
		.card-body {
			padding: 30px;
		}
		>form:focus {
			outline: 0;
		}
		#login {
			visibility: visible;
		}
		#register {
			transform: translateY(-80%) translateX(100%);
			visibility: hidden;
		}
		&.move {
			#register {
				transform: translateY(-80%) translateX(0);
				visibility: visible;
			}
			#login {
				transform: translateX(-100%);
				visibility: hidden;
			}
		}
		>form>div {
			position: relative;
			margin-bottom: 15px;
		}
		label {
			position: absolute;
			top: -7px;
			font-size: 12px;
			white-space: nowrap;
			background: $white;
			text-align: left;
			left: 15px;
			padding: 0 5px;
			color: $color;
			pointer-events: none;
		}
		h3,
		h4 {
			margin-bottom: 25px;
			font-size: 22px;
			color: $color;
		}
		input {
			height: 40px;
			padding: 5px 15px;
			width: 100%;
			border: solid 1px #e4e6f1;
			&:focus {
				outline: 0;
				border-color: #e4e6f1;
			}
		}
	}
	>.wrapper.move {
		left: 45%;
		input:focus {
			border-color: #e0b722;
		}
	}
	width: 100%;
	margin: 0 auto;
	display: table;
	position: relative;
	border-radius: 2px;
	&.single-pageimage {
		min-height: 387px;
		&::before {
			content: '';
			background: $white-5;
		}
	}
	>.log-wrapper {
		display: table-cell;
		vertical-align: middle;
		text-align: right;
		color: $white;
	}
}

@media (min-width: 1024px) {
	.single-pageimage .log-wrapper {
		margin-top: 100px;
	}
}

.customerpage .btn-icon {
	border: 1px solid rgba(96, 94, 126, 0.2);
}

.feature .icons {
	position: relative;
	display: inline-block;
	width: 3em;
	height: 3em;
	line-height: 3em;
	vertical-align: middle;
	border-radius: 50%;
	border: 1px solid $white-1;
}

@keyframes floating {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

.activity {
	position: relative;
	border-left: 1px solid #eaf2f9;
	margin-left: 16px;
	.img-activity {
		width: 42px;
		height: 42px;
		text-align: center;
		line-height: 34px;
		border-radius: 50%;
		position: absolute;
		left: -18px;
		-webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
		box-shadow: 0 0 0 0.5px #f8f9fa;
	}
	.item-activity {
		margin-left: 40px;
		margin-bottom: 19px;
	}
}

.status-border {
	border: 1px solid #e4e6f1 !important;
	&:hover {
		border: 1px solid #e67605 !important;
		i {
			color: #e67605 !important;
		}
	}
}

.horizontal-main-1 {
	background: transparent;
	border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

.fa-1x {
	font-size: 1.5em;
}

@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
}

.info .counter-icon {
	border: 1px solid $black-1 !important;
	background: $black-3;
}

@media (min-width: 411px) and (max-width: 414px) {
	.banner-1 {
		height: 490px !important;
	}
}

.blog-list .item7-card-img {
	height: 196px !important;
}

.blog-list-1 {
	height: 196px;
}

@media screen and (max-width: 1279px) {
	.blog-list .item7-card-img {
		height: 100% !important;
	}
}

.share-icons {
	background: #e4e6f1;
	border: 1px solid #dedee0;
}

.features-desc span {
	width: 50%;
	margin-bottom: 0.5rem;
	float: left;
	margin-right: 0.5rem;
}


/*call-button*/

.call-btn {
	.call-number {
		display: none;
	}
	&.number-btn {
		.call-btn-1 {
			display: none;
		}
		.call-number {
			display: block !important;
		}
	}
}

.sticky-wrapper {
	z-index: 100;
	position: absolute;
	left: 0;
	right: 0;
}

.section-bg {
	background: $white;
}

@media (max-width: 1366px) and (min-width: 993px) {
	.horizontalMenu>.horizontalMenu-list>li>a {
		font-size: 13px;
	}
}

.call-btn.addplus.active {
	.call-number {
		display: block !important;
	}
	.call-btn-1 {
		display: none !important;
	}
}

.testimonia .owl-controls,
.testimonia2 .owl-controls {
	margin-top: 0;
	margin-bottom: 20px;
}

.client-img {
	background: $white;
	border: 1px solid #e4e6f1;
	padding: 5px;
	border-radius: 2px;
}

.animation-effect-1 {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 4%;
	animation: beat .25s infinite alternate;
	opacity: 0.3;
}

.animation-effect-2 {
	position: absolute;
	z-index: 1;
	top: 30%;
	right: 4%;
	animation: beat .25s infinite alternate;
	opacity: 0.3;
}

.animation-effect-3 {
	position: absolute;
	z-index: 1;
	top: 30%;
	right: 15%;
	animation: rotating 2s ease-in forwards infinite;
	opacity: 0.3;
}

@keyframes beat {
	to {
		transform: scale(1.2);
		opacity: 0.2;
	}
}

@keyframes rotating {
	to {
		transform: rotate(360deg);
		opacity: 0.2;
	}
}

.animation-zidex {
	z-index: 1000;
}

.lines {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	margin: auto;
	width: 90vw;
}

.line {
	position: absolute;
	width: 1px;
	height: 100%;
	top: 0;
	left: 50%;
	background: $white-1;
	overflow: hidden;
	&:after {
		content: '';
		display: block;
		position: absolute;
		height: 15vh;
		width: 100%;
		top: -50%;
		left: 0;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $white 75%, $white 100%);
		animation: run 7s 0s infinite;
		animation-fill-mode: forwards;
		animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
	}
	&:nth-child(1) {
		margin-left: -25%;
		&:after {
			animation-delay: 2s;
		}
	}
	&:nth-child(3) {
		margin-left: 25%;
		&:after {
			animation-delay: 2.5s;
		}
	}
	&:nth-child(4) {
		margin-left: -50%;
		&:after {
			animation-delay: 3s;
		}
	}
	&:nth-child(5) {
		margin-left: 50%;
		&:after {
			animation-delay: 3.5s;
		}
	}
}

@keyframes run {
	0% {
		top: -50%;
	}
	100% {
		top: 110%;
	}
}

.graident-animation {
	animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/*Banner*/

.bannner-owl-carousel1 {
	&.banner-height {
		height: auto;
	}
	.slider-img {
		height: 100%;
		width: 100%;
		position: absolute;
		background-size: cover;
	}
	.banner-title {
		text-transform: capitalize;
		font-weight: 400;
		margin-bottom: 0.2rem;
	}
	.banner-subtitle,
	.banner-desc {
		font-weight: 300;
	}
	.owl-nav button {
		border: 1px solid transparent !important;
		background: $black-1 !important;
		border-radius: 3px !important;
	}
}

.slider-images {
	.slider-img1:before,
	.slider-img2:before,
	.slider-img3:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		display: block;
		z-index: 1;
		top: 0;
	}
}

.bannner-owl-carousel1.owl-carousel {
	&:hover .owl-nav button {
		background: $black-3 !important;
		color: #eceff7 !important;
	}
	.owl-nav button {
		color: $white-6 !important;
	}
}

@media (min-width: 992px) {
	.bannner-owl-carousel1 .item {
		height: 450px;
	}
	.slider-images {
		.select2-lg .select2-container .select2-selection--single,
		.form-control.input-lg {
			border-left: 1px solid #e4e6f1 !important;
			border-top: none !important;
			border-bottom: none !important;
			border-radius: 0 !important;
		}
	}
	.search-background.bg-transparent {
		.select2-lg .select2-container .select2-selection--single,
		.form-control.input-lg {
			border-right: 0 !important;
			border-top: none !important;
			border-bottom: none !important;
			border-radius: 0 !important;
		}
	}
	.map-content-width .search-background.bg-transparent {
		.select2-lg .select2-container .select2-selection--single,
		.form-control.input-lg {
			border-right: 0 !important;
			border-top: 1px solid #e4e6f1 !important;
			border-bottom: 1px solid #e4e6f1 !important;
		}
	}
	.index-search-select .select2.select2-container .select2-selection--single {
		border-right: 0 !important;
		border-top: none !important;
		border-bottom: none !important;
		border-radius: 0 !important;
	}
}

@media (min-width: 1367px) {
	.bannner-owl-carousel1 {
		.banner-text {
			position: absolute;
			top: 140px;
			z-index: 2;
			width: 100%;
			color: $white;
			text-align: center;
		}
		.banner-title {
			font-size: 2.5rem;
		}
		.banner-subtitle {
			font-size: 1.8rem;
		}
		.banner-desc {
			font-size: 1rem;
			line-height: 30px;
		}
	}
}

@media (max-width: 480px) {
	.bannner-owl-carousel1 .item {
		height: 350px !important;
	}
}

@media (max-width: 767px) {
	.sptb {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
	.bannner-owl-carousel1 {
		.banner-text {
			top: 65px;
			position: relative;
			z-index: 2;
			color: $white;
			text-align: center;
			&.banner-search {
				top: 60px;
				position: relative;
				z-index: 2;
				color: $white;
				text-align: center;
			}
		}
		.item {
			height: 300px;
		}
		.banner-title {
			font-size: 28px;
			margin-bottom: 0.5rem;
		}
		.banner-subtitle {
			font-size: 18px;
		}
		.banner-desc {
			font-size: 13px;
			line-height: 1;
		}
	}
	.banner-search .form-group {
		margin-bottom: 10px !important;
	}
}

@media (max-width: 1367px) and (min-width: 992px) {
	.bannner-owl-carousel1 .banner-text {
		top: 100px !important;
	}
}

@media (max-width: 1367px) and (min-width: 768px) {
	.bannner-owl-carousel1 {
		.banner-text {
			top: 68px;
			position: relative;
			z-index: 2;
			color: $white;
			text-align: center;
			&.banner-search {
				top: 60px;
				position: relative;
				z-index: 2;
				color: $white;
				text-align: center;
			}
		}
		.item {
			height: 350px;
		}
		.banner-title {
			font-size: 2.2rem;
			margin-bottom: 0.5rem;
		}
		.banner-subtitle {
			font-size: 1.8rem;
		}
		.banner-desc {
			font-size: 1rem;
			line-height: 1;
		}
	}
	.banner-search .form-group {
		margin-bottom: 10px !important;
	}
}

.post-content {
	display: none;
	&.active {
		display: block;
	}
}

.slide-header-text h1 {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 2.5rem;
}

.content-text h1 {
	font-family: 'Poppins', sans-serif;
	font-size: 2rem;
	font-weight: 400;
}

.ui-widget-content {
	border: 1px solid #e4e6f1 !important;
}

.previous.list-inline-item.disabled {
	opacity: 0.3;
}

.page-select .select2.select2-container {
	width: 150px !important;
}

.details-list {
	li {
		position: relative;
		display: block;
		padding-left: 25px;
	}
	i {
		position: absolute;
		left: 0;
		top: 5px;
	}
}


/*Bubble-Animation*/

.bubble-animate {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.bubble {
	background: $white;
	bottom: 0;
	position: absolute;
	border-radius: 50%;
	&.bubble-sm {
		width: 15px;
		height: 15px;
		opacity: 0.7;
		&.x1 {
			left: 18%;
			top: 100%;
			animation-name: smallBubble;
			animation-duration: 3s;
			animation-iteration-count: infinite;
			animation-delay: 1s;
			animation-timing-function: ease-in;
		}
		&.x2 {
			left: 36%;
			top: 100%;
			animation-name: smallBubble;
			animation-duration: 4s;
			animation-iteration-count: infinite;
			animation-delay: 2s;
			animation-timing-function: ease-in;
		}
		&.x3 {
			left: 54%;
			top: 100%;
			animation-name: smallBubble;
			animation-duration: 5s;
			animation-iteration-count: infinite;
			animation-delay: 1s;
			animation-timing-function: ease-in;
		}
		&.x4 {
			left: 72%;
			top: 100%;
			animation-name: smallBubble;
			animation-duration: 6s;
			animation-iteration-count: infinite;
			animation-delay: 4s;
			animation-timing-function: ease-in;
		}
	}
	&.bubble-md {
		width: 35px;
		height: 35px;
		opacity: 0.35;
		&.x5 {
			left: 21%;
			top: 100%;
			animation-name: mediumBubble;
			animation-duration: 5s;
			animation-iteration-count: infinite;
			animation-delay: 2s;
			animation-timing-function: ease-in;
		}
		&.x6 {
			left: 42%;
			top: 100%;
			animation-name: mediumBubble;
			animation-duration: 8s;
			animation-iteration-count: infinite;
			animation-delay: 6s;
			animation-timing-function: ease-in;
		}
		&.x7 {
			left: 63%;
			top: 100%;
			animation-name: mediumBubble;
			animation-duration: 12s;
			animation-iteration-count: infinite;
			animation-delay: 12s;
			animation-timing-function: ease-in;
		}
		&.x8 {
			left: 84%;
			top: 100%;
			animation-name: mediumBubble;
			animation-duration: 4s;
			animation-iteration-count: infinite;
			animation-delay: 12s;
			animation-timing-function: ease-in;
		}
	}
	&.bubble-lg {
		width: 100px;
		height: 100px;
		opacity: 0.15;
		&.x9 {
			left: 21%;
			top: 100%;
			animation-name: bigBubble;
			animation-duration: 6s;
			animation-iteration-count: infinite;
			animation-delay: 6s;
			animation-timing-function: ease-in;
		}
		&.x10 {
			left: 42%;
			top: 100%;
			animation-name: bigBubble;
			animation-duration: 6s;
			animation-iteration-count: infinite;
			animation-delay: 3s;
			animation-timing-function: ease-in;
		}
		&.x11 {
			left: 63%;
			top: 100%;
			animation-name: bigBubble;
			animation-duration: 6s;
			animation-iteration-count: infinite;
			animation-delay: 13s;
			animation-timing-function: ease-in;
		}
		&.x12 {
			left: 84%;
			top: 100%;
			animation-name: bigBubble;
			animation-duration: 6s;
			animation-iteration-count: infinite;
			animation-delay: 9s;
			animation-timing-function: ease-in;
		}
	}
}

@-webkit-keyframes smallBubble {
	0% {
		top: 100%;
		margin-left: 10px;
	}
	25% {
		margin-left: -10px;
	}
	50% {
		margin-left: 10px;
	}
	75% {
		margin-left: -10px;
	}
	100% {
		top: 0%;
		opacity: 0;
		margin-left: 0px;
	}
}

@keyframes smallBubble {
	0% {
		top: 100%;
		margin-left: 10px;
	}
	25% {
		margin-left: -10px;
	}
	50% {
		margin-left: 10px;
	}
	75% {
		margin-left: -10px;
	}
	100% {
		top: 0%;
		opacity: 0;
		margin-left: 0px;
	}
}

@-webkit-keyframes mediumBubble {
	0% {
		top: 100%;
		margin-left: 15px;
	}
	25% {
		margin-left: -15px;
	}
	50% {
		margin-left: 15px;
	}
	75% {
		margin-left: -15px;
	}
	100% {
		top: 0%;
		opacity: 0;
		margin-left: 0px;
	}
}

@keyframes mediumBubble {
	0% {
		top: 100%;
		margin-left: 15px;
	}
	25% {
		margin-left: -15px;
	}
	50% {
		margin-left: 15px;
	}
	75% {
		margin-left: -15px;
	}
	100% {
		top: 0%;
		opacity: 0;
		margin-left: 0px;
	}
}

@-webkit-keyframes bigBubble {
	0% {
		top: 100%;
		margin-left: 20px;
	}
	25% {
		margin-left: -20px;
	}
	50% {
		margin-left: 20px;
	}
	75% {
		margin-left: -20px;
	}
	100% {
		top: 0%;
		opacity: 0;
		margin-left: 0px;
	}
}

@keyframes bigBubble {
	0% {
		top: 100%;
		margin-left: 20px;
	}
	25% {
		margin-left: -20px;
	}
	50% {
		margin-left: 20px;
	}
	75% {
		margin-left: -20px;
	}
	100% {
		top: 0%;
		opacity: 0;
		margin-left: 0px;
	}
}

@-webkit-keyframes animateBubble {
	0% {
		margin-top: 1000px;
	}
	100% {
		margin-top: -100%;
	}
}

@-moz-keyframes animateBubble {
	0% {
		margin-top: 1000px;
	}
	100% {
		margin-top: -100%;
	}
}


/*--- Styles ---*/


/* Horizontal-menu Styles */

.dark-horizontalmenu {
	.horizontal-main {
		background: #0b101f;
	}
	.horizontalMenu>.horizontalMenu-list>li>a {
		color: #d8e1f7;
	}
}

.color-horizontalmenu .horizontalMenu>.horizontalMenu-list>li {
	>a {
		color: $white;
		&.active {
			background: $white-1;
			color: $white;
		}
	}
	&:hover>a {
		background: $white-1;
		color: $white;
	}
}

@media (min-width: 992px) {
	.dark-horizontalmenu .sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li>a.active,
	.color-horizontalmenu .sticky-wrapper.is-sticky .horizontalMenu>.horizontalMenu-list>li>a.active {
		background: $white-1;
		color: $white;
	}
}

@media only screen and (max-width: 991px) {
	.color-horizontalmenu .horizontalMenu>.horizontalMenu-list>li>a {
		border-bottom: 1px solid $white-1;
	}
	.dark-horizontalmenu .horizontalMenu>.horizontalMenu-list {
		>li>a {
			border-bottom: 1px solid $white-1;
		}
		background: #0b101f;
	}
}

.imagecheck-image {
	width: 100%;
}

.blog-list .item7-card-img {
	height: 196px;
}

@media (max-width: 1279px) {
	.blog-list .item7-card-img {
		height: 100%;
	}
}
.tab-content.card-body.border {
    padding: 1.5rem 1.5rem;
}
.card-body.border {
    padding: 1.5rem 1.5rem;
}
.list-inline-item .border {
    padding: 0;
}
/*-- Dashboard--*/
.my-dash .side-menu .slide li a {
    position: relative;
}

.my-dash .slide {
    list-style-type: none;
}

.my-dash li {
    list-style-type: none;
}

.my-dash .side-menu li ul li a.active:before {
    display: none;
}

.my-dash .side-menu__item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.75rem 1.25rem;
    font-size: 14px;
    -webkit-transition: border-left-color 0.3s ease,
        background-color 0.3s ease;
    -o-transition: border-left-color 0.3s ease,
        background-color 0.3s ease;
    transition: border-left-color 0.3s ease,
        background-color 0.3s ease;
    border-top: 1px solid #efecfb;
}

.my-dash.app-sidebar .mCSB_draggerContainer {
    right: -11px;
}

.my-dash .side-menu__icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25px;
}

.my-dash .side-menu__label {
    white-space: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.my-dash .slide.is-expanded [data-toggle='slide'] {
    border-bottom: 0px solid #efecfb;
}

.my-dash .side-menu li a {
    border-top: 1px solid #efecfb;
}

.my-dash .side-menu li ul li a {
    border-top: 0;
    border-bottom: 0;
}


.my-dash.app-sidebar .slide-menu li.active>.a {
    font-weight: 500;
}

.my-dash .slide-menu li ul li {
    font-size: 12px !important;
    font-weight: 300;
    border-bottom: 0px solid #efecfb;
}

.my-dash .slide-menu li ul a {
    padding: 10px 10px 10px 65px;
}

.my-dash .side-menu .slide-menu li a {
    font-size: 13px;
}

.my-dash.slide ul li a {
    color: #605e7e;
    font-weight: 400;
}

.my-dash.app-sidebar-footer {
    clear: both;
    display: block;
    color: #fff;
    position: fixed;
    bottom: 0px;
    background: #fff;
    width: 228px;
    z-index: 999;
    border-top: 1px solid #efecfb;
}

.my-dash .app-sidebar-footer a {
    padding: 10px 0;
    text-align: center;
    width: 20%;
    color: #fff;
    font-size: 15px;
    display: block;
    float: left;
    cursor: pointer;
    border-right: 1px solid #efecfb;
    border-bottom: 1px solid #efecfb;
}

.my-dash .app-sidebar-footer a:hover {
    background: rgba(255, 255, 255, 0.02);
}

.my-dash .slide ul ul a {
    font-size: 13px !important;
    padding: 0px 0px 0px 64px !important;
}

.my-dash.app-sidebar ul li a {
    color: #2e384d;
    font-weight: 400;
}

.my-dash .slide.is-expanded .slide-menu {
    max-height: 150vh;
    background: #fff;
}

.my-dash .slide.is-expanded .angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.my-dash .slide-menu {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.3s ease;
    -o-transition: max-height 0.3s ease;
    transition: max-height 0.3s ease;
}

.my-dash .child-sub-menu {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.3s ease;
    -o-transition: max-height 0.3s ease;
    transition: max-height 0.3s ease;
}

.my-dash .sub-slide.is-expanded .child-sub-menu {
    max-height: 150vh;
    background: #fff;
}

.my-dash .sub-slide.is-expanded .sub-angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.my-dash .sub-slide .sub-angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 20px;
}

.my-dash .slide-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0px 0px 0px 45px;
    font-size: 13px;
    color: #3e4b5b;
    height: 30px;
    line-height: 30px;
}

.my-dash.app-sidebar .side-menu .slide-menu li:last-child {
    padding-bottom: 10px;
}

.my-dash.app-sidebar .side-menu .slide-menu li:last-child {
    border-bottom: 0;
}

.my-dash .slide-item .icon {
    margin-right: 5px;
}

.my-dash .angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.my-dash .app-sidebar ul li a {
    color: #605e7e;
    font-weight: 400;
}

.my-dash .slide.is-expanded .slide-menu {
    max-height: 150vh;
}

.my-dash .slide.is-expanded .angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.my-dash .slide-menu {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.3s ease;
    -o-transition: max-height 0.3s ease;
    transition: max-height 0.3s ease;
}

.my-dash .child-sub-menu li a {
    border-top: 0px solid rgba(0, 0, 0, 0.06) !important;
}

 
.browse-file.form-control{
	background-color: transparent;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.btn-default{
    background: #dde2ef;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    padding: .52rem 15px;
    color: #6a7798;
    border-radius: 0;
}
.header-style2 .header-logo.header-brand-img {
	margin:0 auto;
}