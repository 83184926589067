
.aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid #e4e6f1;
}

.header {
	padding-top: .75rem;
	padding-bottom: .75rem;
	width: 100%;
	z-index: 100;
}

body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

.language-selector {
	width: 100px!important;
    text-align: left;
    background: none!important;
    border-radius: 0px!important;
    line-height: 3!important;
    margin-right: 13px!important;
}

@media print {
	.header {
		display: none;
	}
}

.header {
	.dropdown-menu {
		margin-top: 0;
	}
	.mega-menu {
		width: 350px;
	}
}

.header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
}

.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
	.header-brand-img {
		margin-right: 0;
	}
}

.app-header1 .header-brand {
	line-height: 1.8;
	min-width: 215px;
	text-align: center;
	margin-top: 0;
	.header-brand-img {
		margin-right: 0;
	}
}

.header-brand:hover {
	color: inherit;
	text-decoration: none;
}

.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}

.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
}

.center-block {
	margin-right: auto;
	margin-left: auto;
	float: inherit !important;
	text-align: center;
}

.header-btn.has-new {
	position: relative;
	&:before {
		content: '';
		width: 6px;
		height: 6px;
		background: $danger;
		position: absolute;
		top: 4px;
		right: 4px;
		border-radius: 50%;
	}
}

.header-toggler {
	width: 2rem;
	height: 2rem;
	position: relative;
	color: $white;
	&:hover {
		color: $white;
	}
}

.header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 2px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
}

.header {
	.nav-item .badge,
	.nav-link .badge {
		position: absolute;
		top: 0;
		right: -1px;
		padding: .2rem .25rem;
		min-width: 1rem;
		font-size: 13px;
	}
}

#headerMenuCollapse {
	.nav-item .badge,
	.nav-link .badge {
		position: relative;
		min-width: 1rem;
		font-size: 10px;
		font-weight: 500;
	}
}

@media (max-width: 480px) {
	.app-header .header-brand {
		min-width: 2.8rem;
	}
	.app-header1 .header-brand {
		min-width: auto;
	}
	.menu-toggle-button {
		margin-top: 4px;
	}
}

.app-selector {
	box-shadow: 0 1px 2px 0 $black-05;
	min-width: 16rem;
}

.header-main .social-icons {
	float: right;
	li {
		color: #212529;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: #212529;
		}
	}
}

.header-search {
	padding: 1.5rem 1.5rem;
	background: $white;
	.header-icons {
		.header-icons-link {
			display: flex;
			margin-bottom: 0;
			li {
				background: #f3f3fa;
				width: 40px;
				height: 40px;
				border-radius: 50px;
				text-align: center;
				line-height: 2.5;
				margin-right: 5px;
			}
			.header-icons-link1,
			.header-icons-link2 {
				width: 2.5rem;
				text-align: center;
				height: 2.5rem;
				font-size: 16px;
				position: relative;
			}
		}
		.header-icons-link1 .main-badge1 {
			position: absolute;
			top: -9px;
			right: -15px;
			text-align: center;
			font-size: 10px;
		}
		.header-icons-link.icons li {
			background: $white;
		}
	}
}

.top-bar {
	border-bottom: 1px solid #e4e6f1;
	background: $white;
}

.top-bar-left ul {
	margin-bottom: 0;
}

.top-bar {
	.top-bar-right {
		float: right;
		display: flex;
	}
	.top-bar-left {
		display: flex;
		.socials li {
			display: inline-block;
			float: left;
			font-size: 15px;
			border-left: 1px solid #e4e6f1;
			padding: 10px;
			margin: 0;
		}
		.contact li {
			margin: 10px 5px;
			display: inline-block;
			color: $color;
		}
	}
}

.header-search {
	.header-inputs .input-group-append.searchicon {
		top: 10px;
		right: 25px;
		position: relative;
	}
	.header-search-logo {
		margin-top: 11px;
	}
	.header-nav .nav-cart .icon-cart {
		i {
			font-size: 18px;
			color: #6e82a9;
		}
		a {
			color: #6e82a9;
		}
	}
}

.header-nav {
	display: flex;
}

.header-links {
	padding: 3px;
	li {
		margin: 5px;
		a {
			padding: 9px 18px;
			border-radius: 2px;
		}
	}
}

.header-main {
	.top-bar .contact li {
		padding: 10px;
		margin: 0 !important;
		border-left: 1px solid #e4e6f1;
		line-height: 22px !important;
		&:last-child {
			border-right: 1px solid #e4e6f1;
		}
	}
	.top-bar-right .custom {
		display: flex;
		li {
			padding: 10px;
			border-left: 1px solid #e4e6f1;
			line-height: 22px !important;
			&:last-child {
				margin-right: 0;
				border-right: 1px solid #e4e6f1;
			}
		}
	}
	.ace-responsive-menu li a {
		color: $color;
		&:hover {
			color: $white;
		}
		&:focus {
			color: $black;
		}
	}
	.post-btn {
		float: right;
		margin-left: auto;
	}
}

.header-menu1 {
	float: none !important;
}

@media (min-width: 992px) and (max-width: 1350px) {
	.header-menu1 .input-group {
		width: 140px;
	}

}

.header-main-banner {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	z-index: 99;
	.horizontal-main,
	.horizontalMenu>.horizontalMenu-list {
		background: 0 0;
	}
}

@media (min-width: 992px) {
	.header-main-banner .horizontalMenu>.horizontalMenu-list>li>a {
		color: $white-8;
		>.fa {
			color: $white-8;
		}
	}

	
}

.header-main i {
	color: #e67605;
}

.header-style .horizontalMenu {
	float: inherit !important;
}

.top-bar {
	.select2-dropdown.select2-dropdown--below {
		width: 180px !important;
	}
	.select-country .select2-container {
		width: 150px !important;
	}
}

.header-search {
	.hor-support {
		margin-bottom: 0;
		display: flex;
		width: 60%;
	}
	.support-header {
		border-radius: 0px;
		text-align: center;
		margin-right: 5px;
		padding: 8px 12px;
		width: 100%;
		i {
			float: left;
			margin-right: 10px;
			font-size: 18px;
			line-height: 45px;
			width: 45px;
			text-align: center;
			height: 45px;
			border-radius: 50%;
			background: $white;
			border: 1px solid #e4e6f1;
		}
		p {
			margin-bottom: 0;
			color: #6e82a9;
		}
		.support-text {
			text-align: left;
			float: left;
		}
		h6 {
			font-weight: 500;
			color: $color;
			font-size: 15px;
			margin-bottom: 1px;
			margin-top: 5px;
		}
	}
}

@media (max-width: 480px) {
	.main-header-link {
		display: none;
		width: 0px;
	}

	.header-search {
		.header-support {
			padding: 0 !important;
		}
		.support-header {
			margin-right: 2px;
			padding: 4px 6px;
		}
		.hor-support {
			width: 100% !important;
		}
		.support-header {
			i {
				font-size: 12px;
				line-height: 25px;
				width: 25px;
				height: 25px;
				margin-right: 8px;
			}
			h6 {
				font-size: 10px;
				margin-top: 0;
			}
			p {
				font-size: 9px;
			}
		}
	}
}

@media (max-width: 992px) and (min-width: 481px) {
	.main-header-link {
		display: none;
		width: 0px;
	}
	
	.header-search {
		.hor-support {
			width: 100% !important;
		}
		.support-header {
			margin-right: 2px !important;
			padding: 4px 8px !important;
			i {
				margin-right: 8px;
				font-size: 14px;
				line-height: 35px;
				width: 35px;
				height: 35px;
			}
			h6 {
				font-size: 14px;
				margin-bottom: 1px;
				margin-top: 0;
			}
			p {
				font-size: 12px;
			}
		}
	}
	.header-main .top-bar-right .custom li,
	.top-bar .top-bar-left .socials li {
		border-left: 0;
		&:last-child {
			border-right: 0;
		}
	}
}

@media (max-width: 1366px) and (min-width: 993px) {
	.header-search {
		.hor-support {
			width: 80% !important;
		}
		.support-header {
			padding: 4px 8px;
		}
		.header-search-logo {
			margin-top: 8px;
		}
	}
	.header-main .top-bar-right .custom {
		display: -webkit-inline-box !important;
	}
}

.header-text1 .text-property {
	position: relative;
	top: -15px;
	h1 {
		font-size: 2.5rem;
		font-weight: 300;
	}
}


/* Header-style1*/

.header-style1 {
	.horizontal-main {
		background: #001b44;
	}
	.desktoplogo {
		padding: 0.35rem 0;
	}
	.horizontalMenu {
		>.horizontalMenu-list>li {
			padding: 0.35rem 0;
		}
		ul li a.btn {
			margin-top: 6px;
		}
	}
}

@media (min-width: 992px) {
	.header-style1 {
		.sticky-wrapper.is-sticky {
			.desktoplogo-1 {
				padding: 0.75rem 0;
			}
			.horizontal-main:after {
				display: none;
			}
			.horizontalMenu>.horizontalMenu-list>li>a.active {
				background: $black-1;
				color: $white;
				border-radius: 2px;
			}
		}
		.horizontalMenu>.horizontalMenu-list>li {
			>a {
				color: $white-8;
				&.active {
					background: $white-1;
					color: $white;
					border-radius: 2px;
				}
			}
			&:hover>a {
				background: $white-1;
				color: $white !important;
				border-radius: 2px;
			}
			> {
				ul.sub-menu,
				.horizontal-megamenu {
					top: 52px;
				}
			}
		}
	}
}


/* Header-style2*/

.header-style2 {
	.header-main .top-bar .contact li:last-child {
		border-left: 0;
	}
	.top-bar .top-bar-left .socials li {
		margin: 9px 15px;
		border-left: 0;
		padding: 0;
	}
	&.header-main {
		.top-bar {
			li:hover {
				background: none;
			}
			.contact {
				margin: 9px 0;
				padding-left: 15px;
				li {
					padding: 0;
					border-left: 0;
					&:last-child {
						border-right: 0;
					}
				}
			}
		}
		.top-bar-right .custom {
			margin: 10px 0;
			li {
				padding: 0;
				border-left: 0;
				margin-right: 15px;
			}
		}
	}
}


/* Header-style3*/

.header-style3 {
	.header-main .top-bar .contact li:last-child {
		border-left: 0;
	}
	.top-bar .top-bar-left .socials li {
		margin: 9px 15px;
		border-left: 0;
		padding: 0;
	}
	&.header-main {
		.top-bar {
			li:hover {
				background: none;
			}
			.contact {
				margin: 9px 0;
				padding-left: 15px;
				li {
					padding: 0;
					border-left: 0;
					margin-right: 15px !important;
					&:last-child {
						border-right: 0;
						margin-right: 0 !important;
					}
				}
			}
		}
		.top-bar-right .custom {
			margin: 10px 0;
			li {
				padding: 0;
				border-left: 0;
				margin-right: 15px;
				&:last-child {
					margin-right: 0 !important;
				}
			}
		}
	}
}


/* Top-Header-Styles */

.dark-header {
	.top-bar {
		border-bottom: 1px solid $white-1;
		background: #081027;
	}
	.contact li a.text-dark,
	.header-main .top-bar-right .custom li a.text-dark {
		color: $white !important;
	}
	.top-bar {
		.select2-container--default .select2-selection--single .select2-selection__rendered {
			color: $white !important;
		}
		.top-bar-left .socials li {
			border-left: 1px solid $white-2;
		}
	}
	.header-main {
		.top-bar-right .custom li {
			border-left: 1px solid $white-2;
		}
		.top-bar .contact li {
			border-left: 1px solid $white-2;
			&:last-child {
				border-right: 1px solid $white-2;
			}
		}
		.top-bar-right .custom li:last-child {
			border-right: 1px solid $white-2;
		}
		.top-bar {
			li a.text-dark i {
				color: $white;
			}
			.contact {
				li a.text-dark i.text-muted {
					color: $white-5 !important;
				}
				.select2-container--default .select2-selection--single .select2-selection__arrow b {
					border-color: #ffff80 transparent transparent transparent !important;
				}
			}
		}
	}
}

.color-header {
	.top-bar {
		border-bottom: 1px solid $white-1;
	}
	.contact li a.text-dark,
	.header-main .top-bar-right .custom li a.text-dark {
		color: $white !important;
	}
	.top-bar {
		.select2-container--default .select2-selection--single .select2-selection__rendered {
			color: $white !important;
		}
		.top-bar-left .socials li {
			border-left: 1px solid $white-2;
		}
	}
	.header-main {
		.top-bar-right .custom li {
			border-left: 1px solid $white-2;
		}
		.top-bar .contact li {
			border-left: 1px solid $white-2;
			&:last-child {
				border-right: 1px solid $white-2;
			}
		}
		.top-bar-right .custom li:last-child {
			border-right: 1px solid $white-2;
		}
		.top-bar .contact {
			li a.text-dark i.text-muted {
				color: $white-5 !important;
			}
			.select2-container--default .select2-selection--single .select2-selection__arrow b {
				border-color: #ffff80 transparent transparent transparent !important;
			}
		}
	}
}