.search-box {
    .search-select {
        margin-top: 3px;
        border: none;
    }

    .bootstrap-autocomplete {
        max-height: 554px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 97.5% !important;
        margin-left: -159px !important;
        cursor: pointer;
        .text {
            width: 80%;
            display: inline-block;
            white-space: normal!important;
            padding-left: 15px!important;
            font-size: 14pt;
            cursor: pointer;
        }

        .img {
            width: 50px;
            display: inline-block;
            vertical-align: top;
            text-align: left;
            cursor: pointer;
        }

        .dropdown-item {
            padding-left: 15px!important;
        }
    }

    .country-box {
        width: 16.33%
    }
    .text-box {
        width:75.33%
    }
    .button-box {
        width:8.33%
    }

    @media (max-width: 767px) {
        .country-box {
            width: 100%
        }
        .text-box {
            width: 100%
        }
        .button-box {
            width: 100%
        }
        .bootstrap-autocomplete {
            max-height: 250px;
            overflow-y: auto;
            overflow-x: hidden;
            width: 95.7% !important;
            margin-left: 0px !important;
        }

    }
    
}