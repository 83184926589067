.chart-visitors {
	min-height: 18rem;
}

.chart-tasks {
	height: 15rem;
}

.chart-donut,
.chart-pie {
	height: 21rem;
}

.chartsh {
	height: 16rem;
}

.chartwidget {
	height: 17rem;
}

.chartmorris {
	height: 19rem;
}

.dropshadow {
	-webkit-filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
	filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
}

.chart,
.map {
	position: relative;
	padding-top: 56.25%;
}

.chart-square,
.map-square {
	padding-top: 100%;
}

.chart-content,
.map-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.chart-circle {
	display: block;
	height: 8rem;
	width: 8rem;
	position: relative;
	margin: 0 auto;
	canvas {
		margin: 0 auto;
		display: block;
		max-width: 100%;
		max-height: 100%;
	}
}

.chart-circle-xs {
	height: 2.5rem;
	width: 2.5rem;
	font-size: .8rem;
}

.chart-circle-xs1 {
	height: 110px;
	width: 110px;
	font-size: .8rem;
}

.chart-circle-sm {
	height: 4rem;
	width: 4rem;
	font-size: .8rem;
}

.chart-circle-lg {
	height: 10rem;
	width: 10rem;
	font-size: .8rem;
}

.chart-circle-value {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	line-height: 1;
	small {
		display: block;
		color: #6e82a9;
		font-size: .9375rem;
	}
}

.sparkgraph {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	height: 43px;
	canvas {
		width: 100% !important;
	}
}

.amcharts-chart-div a,
.canvasjs-chart-container a {
	display: none !important;
}

.rotation {
	-webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

.amChartsLegend.amcharts-legend-div {
	display: none;
}