.progress {
	display: -ms-flexbox;
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: .703125rem;
	background-color: #dee0ec;
	border-radius: 2px;
	-webkit-box-shadow: none;
	box-shadow: none;
	position: relative;
}

.progress-bar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	color: $white;
	text-align: center;
	white-space: nowrap;
	transition: width .6s ease;
}

.current-progressbar {
	margin-top: 14.5px;
}

.progress-content {
	margin-bottom: 16.5px;
}

.progress-content:last-child {
	margin-bottom: 0;
}

@media screen and (prefers-reduced-motion:reduce) {
	.progress-bar {
		transition: none;
	}
}

.progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
}

.progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
}

.progress-xs,
.progress-xs .progress-bar {
	height: .25rem;
}

.progress-sm,
.progress-sm .progress-bar {
	height: .5rem;
}

.progress-lg,
.progress-lg .progress-bar {
	height: 1.25rem;
}

.progress-bar-indeterminate:after,
.progress-bar-indeterminate:before {
	content: '';
	position: absolute;
	background-color: inherit;
	left: 0;
	will-change: left, right;
	top: 0;
	bottom: 0;
}

.progress-bar-indeterminate:before {
	-webkit-animation: indeterminate 2.1s cubic-bezier(.65, .815, .735, .395) infinite;
	animation: indeterminate 2.1s cubic-bezier(.65, .815, .735, .395) infinite;
}

.progress-bar-indeterminate:after {
	-webkit-animation: indeterminate-short 2.1s cubic-bezier(.165, .84, .44, 1) infinite;
	animation: indeterminate-short 2.1s cubic-bezier(.165, .84, .44, 1) infinite;
	-webkit-animation-delay: 1.15s;
	animation-delay: 1.15s;
}