.terms {
    font-weight: bold;
    border-bottom: 1px solid #1c46c5;
}

.pricing-card-title {
    small {
        padding-top: 7px;
        padding-bottom: 7px;
        display: block;
    }
}

.m-h-85 {
    min-height: 85px;
}



h4 {
    .flag {
        height: 1.2rem;
        width: 1.6rem;
        padding-right: 7px;
    }
}


.search-bg1 {
    background: url(../../../assets/images/bg/pattern2.jpg);
}


.search-bg2 {
    background: url(../../../assets/images/bg/banner.webp);
}


.bg-404 {
    background: url(../../../assets/images/media/photos/construction.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background: linear-gradient( 86deg, rgba(43, 165, 251, 0.8) 0%, rgba(28, 70, 197, 0.9) 100%);
}


.report-product {
    .price {
        margin: 15px;
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        padding: 25px 0 25px 0;
        text-align: center;
        .fa {
            font-size: 34px;
        }
    }

}
.report-type-list {
    list-style-type: none;
    padding: 0px;
}
.report-type-list li {
    //height: 42px;
    margin-bottom: 20px !important;
    padding: 0;
    margin-left: 10px!important;
}
.report-type-list li div {
    border: 2px solid #e7e7e7;
    border-radius: 20px;

    height: 100%; 
    flex-direction: row; 
    box-sizing: border-box; 
    display: flex; 
    place-content: center space-between; 
    align-items: center;
    border-right: none;
    border-left: none;
}
.report-type-list-border-right li div {
    border-right: 2px solid #e7e7e7!important;
}

.report-type-list-border-right li {
    margin-bottom: -14px !important;
}

.report-type-list-border-right li .form-group {
    padding-top: 10px;
    padding-left: 25px;
    display: block;
    border: none!important;
    .form-control-radio {
        border: none!important;
        width: 100%;
        display: block;
        label {
            i, img {
                height: 24px;
                margin-right: 7px;
                margin-left: 3px;
            }
        }
    }
}

.report-type-list li div p {
    margin: 0 0 0px;
    display: flex;
    line-height: 41px!important;
}

.report-type-list .info { 
    background-color:#f1f1f1;
    min-width: 200px;
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    display: inline-block;
    color: #0075b8;
    height: 42px;
    line-height: 36px;
    padding-left: 15px;
}

.report-type-list li div p span {
    background-size: 26px 26px;
    width: 40px;
    height: 42px;
    margin-right: 7px;
    border: 2px solid #e7e7e7;
    border-radius: 20px;
}

.solvenci-index {
    background: #f1f1f1 url(../../../assets/images/svg/solvenci-index.svg) no-repeat 6px 5px;
}

.credit-limit {
    background: #f1f1f1 url(../../../assets/images/svg/credit-limit.svg) no-repeat 6px 5px;
}

.bank-account {
    background: #f1f1f1 url(../../../assets/images/svg/bank-account.svg) no-repeat 6px 5px;
}

.balances {
    background: #f1f1f1 url(../../../assets/images/svg/balances.svg) no-repeat 6px 5px;
}

.owners {
    background: #f1f1f1 url(../../../assets/images/svg/owners.svg) no-repeat 6px 5px;
}

.management {
    background: #f1f1f1 url(../../../assets/images/svg/management.svg) no-repeat 6px 5px;
}

.events {
    background: #f1f1f1 url(../../../assets/images/svg/events.svg) no-repeat 6px 5px;
}

.language {
    background: #f1f1f1 url(../../../assets/images/products/language-icon.webp) no-repeat 6px 5px;
}

.sing-type-selector {
    background: #f1f1f1 url(../../../assets/images/products/sing-type-icon.webp) no-repeat 6px 5px;
}

.document-type-selector {
    background: #f1f1f1 url(../../../assets/images/products/list-document.webp) no-repeat 6px 5px;
}

.error {
    color: red;
    font-weight: bold;
    text-align: center;
    padding-bottom: 15px;
}

.dashboard {
    .nav {
        li {
            width: 100%;
        }
    }
}


.p-45 {
    padding-top: 75px;
    padding-bottom: 75px;
}

.pb-10 {
    padding-bottom: 10px;
}